import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable()
export class MessageService {
    private _listners = new Subject<any>();
    listen(): Observable<any> {
        return this._listners.asObservable();
    }
    filter(filterBy: object ) {
        this._listners.next(filterBy);
    }
}
