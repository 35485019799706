import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-st-resource-profile',
  templateUrl: './st-resource-profile.component.html',
  styleUrls: ['./st-resource-profile.component.scss'],
})
export class StResourceProfileComponent implements OnInit {

  public accessToken: string;
  public itemPhotoUrl: string;
  public itemName: string;
  public itemImage: number;

  @Input() set token(val: string) {
    this.accessToken = val !== undefined && val !== null ? val : "";
  }

  @Input() set photoUrl(val: string) {
    this.itemPhotoUrl = val !== undefined && val !== null ? val : "";
  }

  @Input() set name(val: string) {
    this.itemName = val !== undefined && val !== null ? val : "";
  }
  constructor() {
    this.itemImage = 3619;
  }

  ngOnInit() { }

}
