import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'n7-modal-status',
  templateUrl: './n7-modal-status.component.html',
  styleUrls: ['./n7-modal-status.component.scss'],
})
export class N7ModalStatusComponent implements OnInit {
  public task: any = null;
  public status: string = '';
  public taskStatuses: any[] = [];
  public taskCircleIcons: any;
  public message = '';
  hasPreviuosStatusFuncionality: boolean;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.task = this.navParams.get('task');
    this.status = this.task.status;

    // console.log('task', this.task);
    // console.log('status', this.status);

    this.taskCircleIcons = {
      draft: '/assets/pins/Draft/Circle.svg',
      submitted: '/assets/pins/Sent/Circle.svg',
      accepted: '/assets/pins/Accepted/Circle.svg',
      'closed-denied': '/assets/pins/Closed/Declined/Circle.svg',
      denied: '/assets/pins/Declined/Circle.svg',
      'in progress': '/assets/pins/Progress/Circle.svg',
      completed: '/assets/pins/Completed/Circle.svg',
      'closed-completed': '/assets/pins/Closed/Completed/Circle.svg',
      open: '/assets/pins/Remark/Open/Circle.svg',
      'closed-open': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      no_actions: '/assets/pins/Remark/NoActions/Circle.svg',
      'closed-no_actions': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      local_actions: '/assets/pins/Remark/Local/Circle.svg',
      'closed-local_actions': '/assets/pins/Remark/Local/Closed/Circle.svg',
      total_actions: '/assets/pins/Remark/Total/Circle.svg',
      'closed-total_actions': '/assets/pins/Remark/Total/Closed/Circle.svg',
      monitored: '/assets/pins/Monitored/Circle.svg',
    };

    this.taskStatuses = environment.settings.task.flowStatuses;
    this.hasPreviuosStatusFuncionality = environment.settings.task.hasPreviuosStatusFuncionality;
  }

  ngOnInit() {
    // console.log(this.task);
  }

  async dismiss(data) {
    await this.modalController.dismiss({
      'dismissed': true,
      'taskId': this.task.id,
      'status': this.status,
      'data': data
    });
  }

  changeTaskStatus() {
    // console.log(this.message);
    // console.log('task ID', this.task.id);
    this.dismiss({
      confirmed: true,
      status: this.status,
      message: this.message,
      task: this.task,
    });
  }

  cancel() {
    this.dismiss({
      confirmed: false
    });
  }

  onStatusClick(status) {
    this.status = status.key;
  }

  canBeNext(current, nextCandidate) {
    const indexCurrentStatus = _.findIndex(this.taskStatuses, function (o) { return o.key == current; });
    if (indexCurrentStatus < 0) {
      return false;
    }
    return ((indexCurrentStatus) && (_.indexOf(this.taskStatuses[indexCurrentStatus].nextKeys, nextCandidate) != -1)) ? true : false;
  }

  canBePrevious(current, previousCandidate) {
    const indexCurrentStatus = _.findIndex(this.taskStatuses, function (o) { return o.key == current; });
    if (indexCurrentStatus < 0) {
      return false;
    }
    return ((indexCurrentStatus) && (_.indexOf(this.taskStatuses[indexCurrentStatus].previousKeys, previousCandidate) != -1)) ? true : false;
  }
}
