import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromBoats from "./boats.actions";
import { Boat } from './boats.model';

export interface BoatsState {
  entities: Boat[];
  isSending: boolean;
  error: string | any;
}

export const initialState: BoatsState = {
  entities: [],
  isSending: false,
  error: null
};

export function reducer(
  state = initialState,
  action: fromBoats.BoatsActions
): BoatsState {

  switch (action.type) {
    case fromBoats.ActionTypes.CleanAllBoats: {
      return initialState;
    }

    case fromBoats.ActionTypes.AddBoat: {
      return {
        ...state,
        entities: [...state.entities, action.payload.data]
      };
    }

    case fromBoats.ActionTypes.RemoveBoat: {
      return {
        ...state,
        entities: state.entities.filter(item => item.id !== action.payload.data),
      };
    }

    default: {
      return state;
    }
  }
}
export const getBoatsState = createFeatureSelector<BoatsState>('boats');

export const selectAllBoats = createSelector(getBoatsState, (state) => (state.entities));

export const selectBoatByID = (id: string) => createSelector(
  selectAllBoats,
  (allBoats) => allBoats.find(Boat => Boat.id === id)
);

export const selectAllBoatsBySiteID = (id: string) => createSelector(
  selectAllBoats,
  (allBoats) => allBoats.filter(Boat => Boat.site_id === id)
);

export const selectFilteredBoats = () => createSelector(
  selectAllBoats,
  (allBoats) => allBoats.filter(Boat => Boat.project)
);

// export const getLoading = (state: BoatsState) => state.loading;

export const getError = (state: BoatsState) => state.error;
