// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-md:after {
  opacity: 0;
}

ion-header ion-toolbar {
  border-bottom: 1px solid #EBEBEB;
}
ion-header .link {
  cursor: pointer;
}
ion-header .breadcrumbs {
  color: #666;
  font-size: 16px;
  display: block;
}
ion-header .breadcrumbs {
  font-weight: 500;
}
ion-header .breadcrumbs.current {
  font-weight: bold;
}
ion-header ion-button {
  position: relative;
  overflow: visible !important;
  color: #cccccc;
}
ion-header ion-button.hidden {
  opacity: 0;
}
ion-header ion-button ion-label {
  text-transform: capitalize;
  letter-spacing: 0;
}
ion-header .buttons-last-slot ion-button {
  color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/n7-task-modal/n7-task-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAGI;EACI,gCAAA;AAAR;AAEI;EACI,eAAA;AAAR;AAEI;EACI,WAAA;EACA,eAAA;EACA,cAAA;AAAR;AAEI;EACI,gBAAA;AAAR;AACQ;EACI,iBAAA;AACZ;AAEI;EACI,kBAAA;EACA,4BAAA;EACA,cAAA;AAAR;AACQ;EACI,UAAA;AACZ;AAIQ;EACI,0BAAA;EACA,iBAAA;AAFZ;AAMQ;EACI,+BAAA;AAJZ","sourcesContent":[".header-md:after {\n    opacity: 0;\n}\n\nion-header {\n    ion-toolbar {\n        border-bottom: 1px solid #EBEBEB;\n    }\n    .link {\n        cursor: pointer;\n    }\n    .breadcrumbs {\n        color: #666;\n        font-size: 16px;\n        display: block;\n    }\n    .breadcrumbs {\n        font-weight: 500;\n        &.current {\n            font-weight: bold;\n        }\n    }\n    ion-button {\n        position: relative;\n        overflow: visible!important;\n        color: #cccccc;\n        &.hidden {\n            opacity: 0;\n        }\n        // ion-icon[slot=start] {\n        //     margin-right: -4px;\n        // }\n        ion-label {\n            text-transform: capitalize;\n            letter-spacing: 0;\n        }\n    }\n    .buttons-last-slot {\n        ion-button {\n            color: var(--ion-color-primary);\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
