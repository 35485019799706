import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSheetController, PopoverController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { AnomalyService } from 'src/app/core/services/anomalies.service';
import { Time } from 'src/app/core/services/management.service';
import { ProjectService } from 'src/app/core/services/projects.service';
import { Utilities } from 'src/common/utilities.class';
import { StPopoverActionsComponent } from '../st-popover-actions/st-popover-actions.component';

@Component({
  selector: 'app-st-time-report-anomalies',
  templateUrl: './st-time-report-anomalies.component.html',
  styleUrls: ['./st-time-report-anomalies.component.scss'],
})
export class StTimeReportAnomaliesComponent implements OnInit {
  
  itemDataRows: any;
  accessToken: string;
  projectId: string;
  readyAnomalies: boolean;
  allChecked: boolean;
  checkedAnomalies: string[];

  @Input() set dataRows(val: any) {
      // this.itemDataRows = val !== undefined && val !== null ? val : [];
  }

  @Input() set token(val: string) {
    this.accessToken = val !== undefined && val !== null ? val : '';
  }

  @Input() set project(val: string) {
    this.projectId = val !== undefined && val !== null ? val : '';
  }

  @Output() onBackClick = new EventEmitter();

  constructor(
    public actionSheetController: ActionSheetController,
    public popoverController: PopoverController,
    public anomaliesService: AnomalyService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.readyAnomalies = false;
    this.allChecked = false;
    this.checkedAnomalies = [];
  }

  async ngOnChanges() {
    if (this.accessToken) {
      this.readyAnomalies = false;
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken });
      this.anomaliesService.headers = headers;
      this.projectService.headers = headers;
      this.itemDataRows = await this.buildDataAnomalyRows();
      this.readyAnomalies = true;
    }
  }
  
  onCheckboxChecked(anomaly){
    if ('all' === anomaly.value) {
      this.allChecked = anomaly.checked;
      this.checkedAnomalies = [];
      this.itemDataRows.forEach((element, index) => {
        // console.log('element', element);
        if (!element.isRead) {
          this.itemDataRows[index].isChecked = anomaly.checked;
          // element.isChecked = anomaly.checked;
          if (anomaly.checked) {
            this.checkedAnomalies.push(element.id);
          }
        }
      });
    }
    else {
      this.allChecked = false;
      const itemDataRowIndex = this.itemDataRows.findIndex((o) => ((o.id) === (anomaly.value)));
      if (-1 !== itemDataRowIndex) {
        this.itemDataRows[itemDataRowIndex].isChecked = anomaly.checked;
      }

      const anomalyIndex = this.checkedAnomalies.findIndex((o) => ((o) === (anomaly.value)));
      if ((0 > anomalyIndex) && (anomaly.checked)) {
        // Add item
        this.checkedAnomalies.push(anomaly.value);
      }
      else {
        if ((-1 !== anomalyIndex) && (!anomaly.checked)) {
          // Remove item
          this.checkedAnomalies.splice(anomalyIndex, 1);
        }
      }
    }
    // console.log('this.checkedAnomalies', this.checkedAnomalies);
  }

  onBack() {
    this.onBackClick.emit();
  }

  async onReadAction(ev: any) {
    // console.log('this.checkedAnomalies', this.checkedAnomalies);
    if (this.checkedAnomalies && this.checkedAnomalies.length) {
      this.anomaliesService.bulkMarkAsRead(this.checkedAnomalies)
      .pipe(take(1))
      .subscribe(async (response: any) => {
        this.readyAnomalies = false;
        // console.log('response', response);
        this.checkedAnomalies = [];
        this.itemDataRows = await this.buildDataAnomalyRows();
        this.readyAnomalies = true;
      });
    }
    else {
      this.readyAnomalies = true;
    }
  }

  // popover mode
  async onGroupAction(ev: any) {
    const popover = await this.popoverController.create({
      component: StPopoverActionsComponent,
      componentProps : {options: [{key:"ignore", value:'Ignore anomalies'},{key:"align", value:'Align to badge stamping'}]},
      cssClass: 'st-popover-actions',
      event: ev,
      translucent: false
    });
    popover.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && (null != data.data.optionSelected)) {
          console.log(data.data.optionSelected);
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });
    return await popover.present();
  }

  async onAnomalyAction(ev: any) {
    const popover = await this.popoverController.create({
      component: StPopoverActionsComponent,
      componentProps : {options: [{key:"modify", value:'Modify app stamping'},{key:"ignore", value:'Ignore anomalies'},{key:"align", value:'Align to badge stamping'}]},
      cssClass: 'st-popover-actions',
      event: ev,
      translucent: false
    });
    popover.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && (null != data.data.optionSelected)) {
          console.log(data.data.optionSelected);
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });
    return await popover.present();
  }


  buildDataAnomalyRows() {
    return new Promise<Time[]>(async (resolve, reject) => {
      try {
        const times = [];
        
        this.projectService.getTimeAnomalies(this.projectId)
          .pipe(take(1))
          .subscribe(async (response) => {
            if (response && response.data) {
              // console.log('TimeReports', response.data);
              Utilities.asyncForEach(response.data, (element) => {
                const time = {
                  id: element.id,
                  photoUrl: element.attributes.resource_profile_image_id,
                  name: element.attributes.resource_full_name,
                  isRead: element.attributes.is_read,
                  isChecked: false,
                  activity: element.attributes.activity_phase,
                  zone: element.attributes.zone_description,
                  appDate: element.attributes.work_date,
                  appStamping: element.attributes.app_stamping,
                }
                times.push(time);
              });
              const dataTimeRows = times;
              // console.log('dataTimeRows', dataTimeRows);
              resolve(dataTimeRows);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }
}
