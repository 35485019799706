import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Utilities } from 'src/common/utilities.class';
import { CompaniesService, CompanyModel } from './companies.service';
import { UserService } from './user.service';

/**
 * Company interface
 */
export interface Company {
  id?: string,
  name: string,
  fiscalCode: string,
}

/**
 * CompanyService
 */
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private accessToken: string;

  constructor(
    private userService: UserService,
    private companiesService: CompaniesService
  ) {
  }

  async authorize() {
    return new Promise((resolve, reject) => {
      try {
        this.userService.getToken().pipe(
          take(1)
        ).subscribe(accessToken => {
          if (!accessToken) {
            // this.router.navigateByUrl('auth');
          } else {
            this.accessToken = accessToken;
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken });
            this.companiesService.headers = headers;
          }
          resolve(true);
        }, (error) => {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Create a new company
   * 
   * @returns 
   */
  createCompany(value: Company) {
    return new Promise<Company>(async (resolve, reject) => {
      try {
        await this.authorize();
        const data = {
          company_business_name: value.name,
          company_fiscal_code: value.fiscalCode,
        };

        const company = this.companiesService.createEntity(CompanyModel, data);
        this.companiesService.addEntity(company).pipe(
          take(1)
        ).subscribe(async (resourceData) => {
          console.log('resourceData', resourceData);
          resolve(resourceData.data);
        },
          (err) => {
            if (err && err.error && err.error.errors && err.error.errors.title && err.error.errors.detail) {
              console.log('err', err.error.errors);
            }
            else {
              console.log('err', err);
            }
            reject();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Retrieve the companies
   * 
   * @returns 
   */
  getCompanies() {
    return new Promise<Company[]>(async (resolve, reject) => {
      try {
        const companies = [];

        await this.authorize();
        this.companiesService.getCollection(CompanyModel).pipe(
          take(1)
        ).subscribe((remoteCompanies) => {
          remoteCompanies.forEach(remoteCompany => {
            const company = {
              id: remoteCompany.id,
              name: remoteCompany.company_business_name
            };
            companies.push(company);
          });
          resolve(companies);
        }, (error) => {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}