import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { N7ModalSuggestionsComponent } from 'src/app/components/n7-modal-suggestions/n7-modal-suggestions.component'
import { ModalController } from '@ionic/angular';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-textarea-suggestions',
  templateUrl: './n7-textarea-suggestions.component.html',
  styleUrls: ['./n7-textarea-suggestions.component.scss'],
})
export class N7TextareaSuggestionsComponent implements OnChanges {
  public index: number;
  public name: string;
  public selfRef: N7TextareaSuggestionsComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemHint: string;
  public itemHasError: boolean;
  public itemError: string;
  public itemValue: string;
  public itemUnit: string;
  public itemType: string;
  public itemPlaceholder: string;
  public itemMinRows: number;
  public itemMaxChars: number;
  public itemIsDisabled: boolean;
  public itemUseSuggestions: boolean;
  public itemSuggestions: any[] = [];

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hint(val: string) {
    this.itemHint = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set value(val: string) {
    this.itemValue = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set unit(val: string) {
    this.itemUnit = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set type(val: string) {
    this.itemType = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set placeholder(val: string) {
    this.itemPlaceholder = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set minRows(val: number) {
    this.itemMinRows = (val !== undefined && val !== null) ? val : 0;
  }

  @Input() set maxChars(val: number) {
    this.itemMaxChars = (val !== undefined && val !== null) ? val : 0;
  }

  @Input() set disabled(val: boolean) {
    this.itemIsDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set useSuggestions(val: boolean) {
    this.itemUseSuggestions = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set suggestions(val: any[]) {
    this.itemSuggestions = (val !== undefined && val !== null) ? val : [];
  }

  @Output() valueChange = new EventEmitter()

  @Output() itemFocus = new EventEmitter()

  @Output() itemBlur = new EventEmitter()

  @Output() suggestionUsed = new EventEmitter()

  constructor(
    private modalController: ModalController
  ) {}

  ngOnChanges() {}

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }
  onItemClear(event) {
    this.itemValue = '';
    this.itemHasError = false;
    this.itemError = '';
    this.valueChange.emit(this.itemValue);
  }

  onItemChange(event) {
    this.valueChange.emit(this.itemValue);
  }

  onKeyUp(event) {
    this.valueChange.emit(this.itemValue);
  }

  onItemFocus(event) {
    this.itemFocus.emit(this.itemValue);
  }

  onItemBlur(event) {
    this.itemBlur.emit(this.itemValue);
  }

  onItemAction(event) {
    this.presentTaskChangeTypeModal();
    // this.valueChange.emit(this.itemValue);
  }


  async presentTaskChangeTypeModal() {
    const modal = await this.modalController.create({
      component: N7ModalSuggestionsComponent,
      componentProps: {
        suggestions: this.itemSuggestions
      },
      cssClass: 'modal-suggestions auto-height'
    });

    modal.onDidDismiss().then((data) => {
      // console.log('data', data);
      if (data !== null) {
        if (data.data && data.data.data && data.data.data.confirmed) {
          this.itemValue = data.data.data.suggestion.text;
          this.valueChange.emit(this.itemValue);
          this.suggestionUsed.emit(data.data.data.suggestion.id);
        }
      }
    });

    return await modal.present();
  }

}
