// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
  margin: 0 0 16px;
  padding: 16px;
  border: 1px solid #DBDBDB;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/n7-item-zone-log/n7-item-zone-log.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AACJ","sourcesContent":[".item {\n    margin: 0 0 16px;\n    padding: 16px;\n    border: 1px solid #DBDBDB;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
