import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ProjectModel } from 'src/app/core/services/projects.service';

@Component({
  selector: 'n7-archived-preview',
  templateUrl: './n7-archived-preview.component.html',
  styleUrls: ['./n7-archived-preview.component.scss'],
})
export class N7ArchivedPreviewComponent implements OnChanges {
  public itemShowBoatName = true;
  public itemHideActions = false;
  public itemDisableView = false;

  public showedType = '';
  public uiStatuses = [
    { name: 'IN INSPECTION', status: null, type: 'inspection' }, // da capire dopo
    { name: 'IN NEW BUILD', status: 'in_site', type: 'newbuild' },
    { name: 'IN OPERATION', status: 'operational', type: null },
    { name: 'IN REFIT', status: 'in_site', type: 'refit' },
  ];


  @Input() project: ProjectModel;

  @Input() set showBoatName(val: boolean) {
    this.itemShowBoatName = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set disableView(val: boolean) {
    this.itemDisableView = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hideActions(val: boolean) {
    this.itemHideActions = (val !== undefined && val !== null) ? val : false;
  }

  @Output() itemClick = new EventEmitter()

  constructor() { }

  ngOnInit() {
    // console.log('init');
    this.showedType = this.project.project_type + ' - ' + (this.project.project_status ? this.project.project_status : this.project.status);
  }

  ngOnChanges() {
    // console.log('change');
    this.showedType = this.project.project_type + ' - ' + (this.project.project_status ? this.project.project_status : this.project.status);
  }

  onItemClick(event) {
    // console.log('itemClick', event);
    this.itemClick.emit(event);
  }
}
