import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
// import * as _ from 'lodash';

@Component({
  selector: 'n7-modal-suggestions',
  templateUrl: './n7-modal-suggestions.component.html',
  styleUrls: ['./n7-modal-suggestions.component.scss'],
})
export class N7ModalSuggestionsComponent implements OnInit {
  public suggestions: any[] = null;
  public selectedSuggestion: any = null;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.suggestions = this.navParams.get('suggestions');
  }

  ngOnInit() {}

  async dismiss(data) {
    await this.modalController.dismiss({
      'dismissed': true,
      'data': data
    });
  }

  changeSuggestion() {
    this.dismiss({
      confirmed: true,
      suggestion: this.selectedSuggestion
    });
  }

  cancel() {
    this.dismiss({
      confirmed: false
    });
  }

  onSuggestionClick(suggestion) {
    this.selectedSuggestion = suggestion;
  }
}
