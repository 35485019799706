import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { } from 'reflect-metadata'
import { map, catchError } from 'rxjs/operators';
import { throwError, of, Observable } from 'rxjs';

export function Attribute() {
  return function (target: any, propertyName: string) {
    const getter = function () {
      return this[`_${propertyName}`];
    };

    const setter = function (newVal: any) {
      this[`_${propertyName}`] = newVal;
    };

    if (delete target[propertyName]) {
      const metadata = Reflect.getMetadata('Attribute', target) || {};
      Reflect.defineMetadata('Attribute', metadata, target);

      const mappingMetadata = Reflect.getMetadata('AttributeMapping', target) || {};
      mappingMetadata[propertyName] = propertyName;
      Reflect.defineMetadata('AttributeMapping', mappingMetadata, target);

      Object.defineProperty(target, propertyName, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
      });
    }
  };
}

export interface JsonApiConfig {
  baseUrl?: string;
  apiVersion?: string;
  models?: object;
}

export type MetaModelType<T> = { new(response: any): T; };

export class JsonApiMetaModel {
  public links: Array<any>;
  public meta: any;

  constructor(response: any) {
    this.links = response.links || [];
    this.meta = response.meta;
  }


}

export interface JsonApiError {
  id?: string;
  links?: Array<any>;
  status?: string;
  code?: string;
  title?: string;
  detail?: string;
  source?: {
    pointer?: string;
    parameter?: string
  };
  meta?: any;
}

export class ErrorResponse {
  errors?: JsonApiError[] = [];

  constructor(errors?: JsonApiError[]) {
    if (errors) {
      this.errors = errors;
    }
  }
}

export interface ModelConfig<T = any> {
  type: string;
  apiVersion?: string;
  baseUrl?: string;
  modelEndpointUrl?: string;
  meta?: MetaModelType<T>;
}

export function JsonApiConfig(config: any = {}) {
  return function (target: any) {
    Reflect.defineMetadata('JsonApiConfig', config, target);
  };
}

export function JsonApiModelConfig(config: ModelConfig) {
  return function (target: any) {
    if (typeof config.meta === 'undefined' || config.meta == null) {
      config.meta = JsonApiMetaModel;
    }

    Reflect.defineMetadata('JsonApiModelConfig', config, target);
  };
}

export class JsonApiModel {
  type: string;
  id: string;

  [key: string]: any;

  meta?: any;
  attributes: any;
  relationships?: any;
  links?: any;

  constructor(private service: JsonapiService, data?: any) {
    if (data) {
      this.type = data.type;
      this.id = data.id;

      this.attributes = data.attributes;

      if (data.relationships) {
        this.relationships = data.relationships;
      }

      if (data.links) {
        this.links = data.links;
      }

      if (data.meta) {
        this.meta = data.meta;
      }

      Object.assign(this, data.attributes);
    }
  }

  get modelConfig(): ModelConfig {
    return Reflect.getMetadata('JsonApiModelConfig', this.constructor);
  }
}

export type ModelType<T extends JsonApiModel> = { new(service: JsonapiService, data: any): T; };

@Injectable({
  providedIn: 'root'
})
export class JsonapiService {
  private globalHeaders: HttpHeaders;
  private globalRequestOptions: object = {};

  protected config: JsonApiConfig;

  // TODO GESTIRE GLI STATI
  // 200
  // 201
  // 204
  // 404
  // 422
  // 500

  constructor(
    protected http: HttpClient
  ) { }

  set headers(headers: HttpHeaders) {
    this.globalHeaders = headers;
  }

  set requestOptions(requestOptions: object) {
    this.globalRequestOptions = requestOptions;
  }

  public get JsonApiConfig(): JsonApiConfig {
    const configFromDecorator: JsonApiConfig = Reflect.getMetadata('JsonApiConfig', this.constructor);
    return Object.assign(configFromDecorator, this.config);
  }

  protected buildRequestOptions(customOptions: any = {}): object {
    // console.log('customOptions', customOptions);
    const httpHeaders: HttpHeaders = this.buildHttpHeaders(customOptions.headers);

    const httpParams: HttpParams = this.buildHttpParams(customOptions.params);

    const requestOptions: object = Object.assign(customOptions, {
      headers: httpHeaders,
      params: httpParams
    });
    return Object.assign(Object.create(this.globalRequestOptions), requestOptions);
    // return Object.assign(requestOptions, this.globalRequestOptions);
  }

  protected buildHttpHeaders(customHeaders?: HttpHeaders): HttpHeaders {
    let requestHeaders: HttpHeaders = new HttpHeaders({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    });

    if (this.globalHeaders) {
      this.globalHeaders.keys().forEach((key) => {
        if (this.globalHeaders.has(key)) {
          requestHeaders = requestHeaders.set(key, this.globalHeaders.get(key)!);
        }
      });
    }

    if (customHeaders) {
      customHeaders.keys().forEach((key) => {
        if (customHeaders.has(key)) {
          requestHeaders = requestHeaders.set(key, customHeaders.get(key)!);
        }
      });
    }

    return requestHeaders;
  }

  protected buildHttpParams(customParams?: any): HttpParams {
    let requestParams: HttpParams = new HttpParams({
    });

    // TODO
    // if (this.globalParams) {
    // }

    if (customParams) {
      if (customParams.filter) {
        for (let [key, value] of Object.entries(customParams.filter)) {
          let strKey = 'filter[' + String(key) + ']';
          let strValue = String(value);
          requestParams = requestParams.append(strKey, strValue);
        }
      }
      if (customParams.page) {
        for (let [key, value] of Object.entries(customParams.page)) {
          let strKey = 'page[' + String(key) + ']';
          let strValue = String(value);
          requestParams = requestParams.append(strKey, strValue);
        }
      }
      if (customParams.sort) {
        requestParams = requestParams.append('sort', customParams.sort);
      }
    }

    return requestParams;
  }

  protected handleError(error: any): Observable<any> {
    if (
      error instanceof HttpErrorResponse &&
      error.error instanceof Object &&
      error.error.errors &&
      error.error.errors instanceof Array
    ) {
      const errors: ErrorResponse = new ErrorResponse(error.error.errors);
      // console.log('errors', error);
      return throwError(errors);
    }

    console.log('error', error);
    return throwError(error);
  }


  protected buildUrl<T extends JsonApiModel>(
    modelType: ModelType<T>,
    params?: any,
    id?: string,
    relatedModelType?: ModelType<any>,
    customUrl?: string
  ): string {
    const queryParams: string = '';

    if (customUrl) {
      const queryParams: string = params ? params : '';
      return queryParams ? `${customUrl}?${queryParams}` : customUrl;
    }

    const modelConfig: ModelConfig = Reflect.getMetadata('JsonApiModelConfig', modelType);
    const baseUrl = modelConfig.baseUrl || this.JsonApiConfig.baseUrl;
    const apiVersion = modelConfig.apiVersion || this.JsonApiConfig.apiVersion;
    const modelEndpointUrl: string = modelConfig.modelEndpointUrl || modelConfig.type;
    let relationship = undefined;
    if (relatedModelType) {
      const relatedModelConfig: ModelConfig = Reflect.getMetadata('JsonApiModelConfig', relatedModelType);
      relationship = relatedModelConfig.type;
    }
    const url: string = [baseUrl, apiVersion, modelEndpointUrl, id, relationship].filter((x) => x).join('/');

    return queryParams ? `${url}?${queryParams}` : url;
  }

  protected extractPageData<T extends JsonApiModel>(
    res: HttpResponse<Object>,
    modelType: ModelType<T>
  ): Array<T> {
    const body: any = res.body;
    let page: any = {};

    if (body && body.meta && body.meta.page) {
      // console.log(body.data);
      page = body.meta.page;
    }

    return page;
  }

  protected extractCollectionData<T extends JsonApiModel>(
    res: HttpResponse<Object> | any,
    modelType: ModelType<T>
  ): Array<T> {
    const body: any = res.body;
    const models: T[] = [];

    if (body && body.data) {
      // console.log(body.data);
      body.data.forEach((data: any) => {
        const model: T = this.deserializeModel(modelType, data);
        delete model['service'];

        models.push(model);
      });
    }
    else if (res && res.data) {
      // console.log(res.data);
      res.data.forEach((data: any) => {
        const model: T = this.deserializeModel(modelType, data);
        delete model['service'];

        models.push(model);
      });
    }

    return models;
  }

  protected extractEntityData<T extends JsonApiModel>(
    res: HttpResponse<Object>,
    modelType: ModelType<T>,
    model?: T
  ): T {
    const body: any = res.body;
    if (!body || body === 'null') {
      throw new Error('no body in response');
    }

    if (!body.data) {
      if (res.status === 201 || !model) {
        throw new Error('expected data in response');
      }
      return model;
    }

    if (model) {
      model.id = body.data.id;
      Object.assign(model, body.data.attributes);
    }

    const deserializedModel = model || this.deserializeModel(modelType, body.data);
    delete deserializedModel['service'];

    return deserializedModel;
  }

  public deserializeModel<T extends JsonApiModel>(modelType: ModelType<T>, data: any) {
    data.attributes = this.transformSerializedNamesToPropertyNames(modelType, data.attributes);
    return new modelType(this, data);
  }

  public transformSerializedNamesToPropertyNames<T extends JsonApiModel>(modelType: ModelType<T>, attributes: any) {
    const serializedNameToPropertyName = this.getModelPropertyNames(modelType.prototype);
    const properties: any = {};

    Object.keys(serializedNameToPropertyName).forEach((serializedName) => {
      if (attributes && attributes[serializedName] !== null && attributes[serializedName] !== undefined) {
        properties[serializedNameToPropertyName[serializedName]] = attributes[serializedName];
      }
    });

    return properties;
  }

  protected getModelPropertyNames(model: JsonApiModel) {
    return Reflect.getMetadata('AttributeMapping', model) || [];
  }

  public getCollection<T extends JsonApiModel>(
    modelType: ModelType<T>,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    // console.log('customUrl', customUrl);
    const url: string = this.buildUrl(modelType, params, undefined, undefined, customUrl);
    // console.log('url', url);
    const requestOptions: object = this.buildRequestOptions({ headers, params, observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          let data = this.extractCollectionData(res, modelType);
          return data;

          // TODO
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getPagedCollection<T extends JsonApiModel>(
    modelType: ModelType<T>,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const url: string = this.buildUrl(modelType, params, undefined, undefined, customUrl);
    const requestOptions: object = this.buildRequestOptions({ headers, params, observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          let data = this.extractCollectionData(res, modelType);
          let page = this.extractPageData(res, modelType);
          let response = {
            data: data,
            page: page
          }
          return response;

          // TODO
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getEntity<T extends JsonApiModel>(
    modelType: ModelType<T>,
    id: string,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const url: string = this.buildUrl(modelType, params, id, undefined, customUrl);
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          let data = this.extractEntityData(res, modelType);
          return data;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public createEntity<T extends JsonApiModel>(modelType: ModelType<T>, data?: any): T {
    return new modelType(this, { attributes: data });
  }

  public addEntity<T extends JsonApiModel>(
    model: T,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const modelType = <ModelType<T>>model.constructor;
    const modelConfig: ModelConfig = model.modelConfig;
    const typeName: string = modelConfig.type;
    const url: string = this.buildUrl(modelType, params, null, undefined, customUrl);
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });

    let httpCall: Observable<HttpResponse<object>>;
    const body: any = {
      data: {
        type: typeName,
        attributes: model.attributes
      }
    }

    httpCall = this.http.post<object>(url, body, requestOptions) as Observable<HttpResponse<object>>;

    return httpCall
      .pipe(
        map((res) => {
          // console.log('HttpResponse', res);
          // TODO
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public updateEntity<T extends JsonApiModel>(
    id: string,
    model: T,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const modelType = <ModelType<T>>model.constructor;
    const modelConfig: ModelConfig = model.modelConfig;
    const typeName: string = modelConfig.type;
    const url: string = this.buildUrl(modelType, params, id, undefined, customUrl);
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });

    let httpCall: Observable<HttpResponse<object>>;
    const body: any = {
      data: {
        type: typeName,
        id: id,
        attributes: model.attributes
      }
    }

    //   console.log('HttpCall url', url);
    //   console.log('HttpCall body', body);
    httpCall = this.http.patch<object>(url, body, requestOptions) as Observable<HttpResponse<object>>;

    return httpCall
      .pipe(
        map((res) => {
          // console.log('HttpResponse', res);
          // TODO
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public deleteEntity<T extends JsonApiModel>(
    modelType: ModelType<T>,
    id: string,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const url: string = this.buildUrl(modelType, null, id, undefined, customUrl);
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    return this.http.delete(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          // TODO
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }


  // TODO
  // getRelated(id, type)
  // getRelated(id, type) // Full


  public getRelatedCollection<T extends JsonApiModel>(
    modelType: ModelType<T>,
    id: string,
    relatedModelType: ModelType<any>,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const url: string = this.buildUrl(modelType, params, id, relatedModelType, customUrl);
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          const data = this.extractCollectionData(res, relatedModelType);
          return data;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }
}
