import { Utilities } from './../../../common/utilities.class';
import { TaskService, TaskModel } from 'src/app/core/services/tasks.service';
import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { } from '@ionic/angular';
import { Map, CRS, imageOverlay, marker, icon } from 'leaflet';
import 'leaflet.markercluster';
import { UserService } from 'src/app/core/services/user.service';
import { BoatsService, BoatModel } from 'src/app/core/services/boats.service';
import { DocumentsService, DocumentModel } from 'src/app/core/services/documents.service';
import { take } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { SectionModel } from 'src/app/core/services/sections.service';
import * as _ from 'lodash';
// import domtoimage from 'dom-to-image';
import { ProjectModel, ProjectService } from 'src/app/core/services/projects.service';

@Component({
  selector: 'app-print-map',
  templateUrl: './print-map.component.html',
  styleUrls: ['./print-map.component.scss'],
})
export class PrintMapComponent implements OnInit, OnChanges {

  constructor(
    private userService: UserService,
    private boatService: BoatsService,
    private documentService: DocumentsService,
    private taskservice: TaskService,
    private projectService: ProjectService
  ) { }

  private maps: Array<Map> = [];
  private accesstoken;

  private loaded = false;
  public boatSections;
  public images = [];
  private tasks: Array<TaskModel> = [];
  private marker = icon({
    // iconUrl: '/assets/icon/pin-poi-grey.png',
    iconUrl: '/assets/pins/Location/Active.svg',
    shadowUrl: '',
    iconSize: [32, 32], // size of the icon
    shadowSize: [0, 0], // size of the shadow
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor,
  });
  @Input() boatId;
  @Input() projectId;
  @Input() primaryFilters;
  @Input() remarkFilters;
  @Input() interventTypeFilters;
  @Input() taskIsOpenFilter;

  ngOnInit() {
    this.primaryFilters = '';
    this.remarkFilters = '';
    this.interventTypeFilters = '';
    this.taskIsOpenFilter = true;

    this.userService.getToken().pipe(
      take(1)
    ).subscribe(accesstoken => {
      this.accesstoken = accesstoken;
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accesstoken });
      this.boatService.headers = headers;
      this.documentService.headers = headers;
      this.projectService.headers = headers;
    });
    this.loaded = false;

    this.loadTasks();
  }

  createMap(section): void {
    // _.forEach(this.maps, (m) => { console.log('mappa'); m.off(); m.remove();} )
    const map = new Map(section.id, { preferCanvas: true, attributionControl: false, zoomControl: false });
    map.options.crs = CRS.Simple;
    map.options.minZoom = -5;
    map.options.maxZoom = 5;
    map.scrollWheelZoom.disable();

    this.getImageMeta(section.rasterImage, (width, height) => {
      const bounds: any = [[0, 0], [height, width]];
      map.fitBounds(bounds);
      /* caso immagine png o jpg */
      imageOverlay(section.rasterImage, bounds).addTo(map).on('load', async (event) => {
        await this.addMarkers(section.id, map);
        this.maps.push(map);
        return map;
      }).on('error', (event) => {
        console.log(event);
      });
    });
  }

  loadTasks() {
    let params = '';
    if (this.primaryFilters) {
      params = 'filter[status]=' + this.primaryFilters;
    } else if (this.remarkFilters) {
      params = 'filter[remark_status]=' + this.remarkFilters;
      // params = 'filter[status]=' + this.remarkFilters  + '&filter[task_type]=remark&filter[is_open]=' + (this.taskIsOpenFilter ? 1 : 0);
    }
    if (this.interventTypeFilters) {
      let conjunction = (params === '') ? '' : '&';
      params += conjunction + 'filter[intervent_types]=' + this.interventTypeFilters;
    }
    // console.log('params: ' + params );
    this.projectService.getRelatedCollection(ProjectModel, this.projectId, TaskModel, params).pipe(
      take(1)
    ).subscribe((tasks) => {
      this.tasks = tasks;
      this.loadSections();
    });
  }

  do() {
    this.images = [];
    this.maps.forEach((map) => {
      this.renderMap(map);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if (changes.hasOwnProperty('primaryFilters') ||
      changes.hasOwnProperty('remarkFilters') ||
      changes.hasOwnProperty('interventTypeFilters') ||
      changes.hasOwnProperty('taskIsOpenFilter')) {
      this.interventTypeFilters
      // console.log(' - FILTRI: (t): ' + this.interventTypeFilters + ' + (p): ' + this.primaryFilters + ', (r): ' + this.remarkFilters + ', is_open: [' + this.taskIsOpenFilter + ']');
      this.loaded = false;
      this.loadTasks();
    }
  }

  async asyncForEach(source, callback) {
    if (source instanceof Array) {
      for (let index = 0; index < source.length; index++) {
        await callback(source[index], index, source);
      }
    }
    else {
      for (var key in source) {
        if (source.hasOwnProperty(key)) {
          await callback(source[key], key, source);
        }
      }
    }
  }

  async addMarkers(sectionId, map) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        await this.asyncForEach(this.tasks, async task => {
          if (task.section_id == sectionId) {
            marker([task.x_coord, task.y_coord], {
              icon: icon({
                iconUrl: Utilities.getIconPath(task.status, task.is_open),
                shadowUrl: '',
                iconSize: [32, 32], // size of the icon
                shadowSize: [0, 0], // size of the shadow
                iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
                shadowAnchor: [0, 0],  // the same for the shadow
                popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor,
              })
            }).addTo(map).bindPopup('#' + task.internal_progressive_number);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  loadSections() {
    this.images = [];
    this.boatService.getRelatedCollection(BoatModel, this.boatId, SectionModel).pipe(
      take(1)
    ).subscribe((sections) => {
      this.boatSections = _.orderBy(sections, ['position'], ['asc']);
      const sectionNumber = (Object.keys(sections).length);
      this.boatSections.forEach((section, index) => {
        if (section.meta.image) {
          this.userService.getValue('imageId' + section.meta.image).then((value: string) => {
            if (value === null) {
              this.documentService.getImage(DocumentModel, section.meta.image).pipe(
                take(1)
              ).subscribe((document: any) => {
                if ('data:' === document.substring(0, 5)) {
                  // console.log('section document is raster image');
                  this.boatSections[index].rasterImage = value;
                  this.createMap(this.boatSections[index]);
                } else {
                  // console.log('section document is svg image');
                  this.boatSections[index].svg = document;
                }
                this.userService.setValue('imageId' + section.meta.image, document);
              });
            } else {
              if ('data:' === value.substring(0, 5)) {
                this.boatSections[index].rasterImage = value;
                const map = this.createMap(this.boatSections[index]);
              } else {
                // console.log('section document is svg image');
                this.boatSections[index].svg = value;
              }
            }
          });
        }
        if (sectionNumber === (index + 1)) {
          this.loaded = true;
          // console.log('all maps are loaded');
        }
      });
    });
  }

  /**
   * Renderizza la mappa
   * @param map
   */
  renderMap(map: Map) {
    // const mapContainer = map.getContainer();
    // const originalState = {
    //   mapWidth: mapContainer.style.width,
    //   widthWasAuto: false,
    //   widthWasPercentage: false,
    //   mapHeight: mapContainer.style.height,
    //   zoom: map.getZoom(),
    //   center: map.getCenter()
    // };
    // domtoimage.toPng(mapContainer, {
    //   width: 1754,
    //   height: 1240,
    // })
    //   .then((dataUrl) => {
    //     // const blob = this.dataURItoBlob(dataUrl); // da dataUri a file blob utile se si vuole produrre l,anteprima
    //     this.images.push(dataUrl);
    //   })
    //   .catch((error) => {
    //     // console.error('oops, something went wrong!', error);
    //   });

  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const dw = new DataView(ab);
    for (let i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }
    return new Blob([ab], { type: mimeString });
  }

  getImageMeta(url, callback) {
    const img = new Image;
    img.src = url; //'https://splashbase.s3.amazonaws.com/unsplash/regular/tumblr_mtax0twHix1st5lhmo1_1280.jpg';//url;
    img.onload = () => { callback(img.width, img.height); };
  }

}
