import { Action } from "@ngrx/store";
import { Boat } from "./boats.model";

export enum ActionTypes {
  CleanAllBoats = "[BOATS State] Clean All",

  AddBoat = "[BOATS State] Add Boat",
  RemoveBoat = "[BOATS State] Remove Boat",
}

export class CleanAllBoats implements Action {
  readonly type = ActionTypes.CleanAllBoats;

  constructor() { }
}

export class AddBoat implements Action {
  readonly type = ActionTypes.AddBoat;

  constructor(public payload: { data: Boat }) { }
}

export class RemoveBoat implements Action {
  readonly type = ActionTypes.RemoveBoat;

  constructor(public payload: { data: string | number }) { }
}

export type BoatsActions =
  CleanAllBoats
  | AddBoat
  | RemoveBoat;
