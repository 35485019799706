// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas-wrapper {
  position: relative;
  text-align: center;
}

#canvas {
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #FFF;
}

#canvasEdit {
  position: absolute;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/n7-canvas/n7-canvas.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;AACF;;AAEA;EAIE,WAAA;EACA,YAAA;EACA,2CAAA;EACA,sBAAA;AAFF;;AAMA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;AAHF","sourcesContent":[".canvas-wrapper {\n  position: relative;\n  text-align: center;\n}\n\n#canvas {\n  // position: absolute;\n  // top: 0;\n  // left: 0;\n  width: 100%;\n  height: auto;\n  border-bottom: 1px solid rgba(0,0,0,0.3);\n  background-color: #FFF;\n  // box-shadow: 0 9px 20px -5px rgba(0,0,0,0.8);\n}\n\n#canvasEdit {\n  position: absolute;\n  top: 0;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
