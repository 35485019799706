import * as fromUser from "./user.actions";
import { User } from "./user.model";

export interface UserState {
  user: User;
  token_type: string,
  expires_in: number,
  access_token: string,
  refresh_token: string,
  loading: boolean;
  error: any;
  showedHints: string[];
}

export const initialState: UserState = {
  user: {
    id: '',
    name: '',
    surname: '',
    email: '',
    email_verified_at: '',
    disable_login: 0,
    hasPhoto: false,
    in_house: 0,
    is_admin: false,
    is_backend_manager: false,
    is_boat_manager: false,
    is_member: false,
    is_pm: false,
    is_team_leader: false,
    can_write: 1,
    created_at: '',
    updated_at: '',
    forced_roles: [],
    forced_permissions: []
  },
  token_type: '',
  expires_in: 0,
  access_token: '',
  refresh_token: '',
  loading: false,
  error: null,
  showedHints: []
};

export function reducer(
  state = initialState,
  action: fromUser.ActionsUnion
): UserState {
  switch (action.type) {
    case fromUser.ActionTypes.LoginUserBegin: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case fromUser.ActionTypes.LoginUserSuccess: {
      return {
        ...state,
        loading: false,
        token_type: action.payload.data.token_type,
        expires_in: action.payload.data.expires_in,
        access_token: action.payload.data.access_token,
        refresh_token: action.payload.data.refresh_token,
      };
    }

    case fromUser.ActionTypes.LoginUserFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }

    case fromUser.ActionTypes.RefreshTokenBegin: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case fromUser.ActionTypes.RefreshTokenSuccess: {
      return {
        ...state,
        loading: false,
        token_type: action.payload.data.token_type,
        expires_in: action.payload.data.expires_in,
        access_token: action.payload.data.access_token,
        refresh_token: action.payload.data.refresh_token,
      };
    }

    case fromUser.ActionTypes.RefreshTokenFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }

    case fromUser.ActionTypes.LogoutUserBegin: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case fromUser.ActionTypes.LogoutUserSuccess: {
      return {
        ...state,
        loading: false,
        token_type: initialState.token_type,
        expires_in: initialState.expires_in,
        access_token: initialState.access_token,
        refresh_token: initialState.refresh_token,
        user: initialState.user,
        showedHints: initialState.showedHints
      };
    }

    case fromUser.ActionTypes.LogoutUserFailure: {
      return {
        ...state,
        loading: false,
        token_type: initialState.token_type,
        expires_in: initialState.expires_in,
        access_token: initialState.access_token,
        refresh_token: initialState.refresh_token,
        user: initialState.user,
        error: action.payload.error,
        showedHints: initialState.showedHints
      };
    }


    case fromUser.ActionTypes.LoadUserBegin: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case fromUser.ActionTypes.LoadUserSuccess: {
      return {
        ...state,
        loading: false,
        user: action.payload.data.data.attributes
      };
    }

    case fromUser.ActionTypes.LoadUserFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }

    case fromUser.ActionTypes.ShowedHint: {
      return {
        ...state,
        showedHints: [...state.showedHints, action.payload.hint]
      };
    }

    default: {
      return state;
    }
  }
}

export const getUser = (state: UserState) => state.user;

export const getToken = (state: UserState) => state.access_token;

export const isLoading = (state: UserState) => state.loading;

export const getShowedHints = (state: UserState) => state.showedHints;