import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Time } from 'src/app/core/services/management.service';
import { ProjectService } from 'src/app/core/services/projects.service';
import { Utilities } from 'src/common/utilities.class';

@Component({
  selector: 'app-st-time-report',
  templateUrl: './st-time-report.component.html',
  styleUrls: ['./st-time-report.component.scss'],
})
export class StTimeReportComponent implements OnInit {
  itemDataRows: any;
  accessToken: string;
  projectId: string;
  readyReports: boolean;

  @Input() set dataRows(val: any) {
    this.itemDataRows = val !== undefined && val !== null ? val : [];
  }

  @Input() set token(val: string) {
    this.accessToken = val !== undefined && val !== null ? val : '';
  }

  @Input() set project(val: string) {
    this.projectId = val !== undefined && val !== null ? val : '';
  }

  constructor(
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.readyReports = false;
  }

  async ngOnChanges() {
    if (this.accessToken) {
      this.readyReports = false;
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken });
      this.projectService.headers = headers;
      this.itemDataRows = await this.buildDataTimeRows();
      this.readyReports = true;
    }
  }

  buildDataTimeRows() {
    return new Promise<Time[]>(async (resolve, reject) => {
      try {
        const times = [];
        this.projectService.getTimeReports(this.projectId)
          .pipe(take(1))
          .subscribe(async (response) => {
            if (response && response.data) {
              // console.log('TimeReports', response.data);
              Utilities.asyncForEach(response.data, (element) => {
                const time = {
                  id: element.id,
                  photoUrl: element.attributes.resource_profile_image_id,
                  name: element.attributes.resource_full_name,
                  dailyAverage: Utilities.formatTimeHours(element.attributes.daily_average_hours),
                  lastActivity: element.attributes.last_activity_phase,
                  lastZone: element.attributes.last_zone_description,
                }
                times.push(time);
              });
              const dataTimeRows = times;
              // console.log('dataTimeRows', dataTimeRows);
              resolve(dataTimeRows);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  exportCSV() {
    let fileMIMEType = 'text/csv';

    this.projectService.getTimereportCSV(this.projectId, 'report-csv').subscribe((res) => {
      console.log('res', res);
      console.log('res body', res.body);
      var blob = new Blob([res.body], { type: fileMIMEType });
      // saveAs(blob, 'report-csv' + ".csv");
    }, (err) => {
      console.log('err', err);
    });

  }
}
