import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { throwError, of, Observable } from 'rxjs';

import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, ModelType, Attribute } from './jsonapi.service';
import { environment } from '../../../environments/environment';
import { encode } from 'punycode';

@JsonApiModelConfig({
  type: 'documents'
})

export class DocumentModel extends JsonApiModel {
  @Attribute()
  title: string;

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}

const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    documents: DocumentModel
  }
}



@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class DocumentsService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }


  getBase64(file, target) {
    const reader = new FileReader();
    let encoded = '';
    reader.readAsDataURL(file);

    reader.onload = () => {
      return reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      target = encoded;
    },
      reader.onerror = (error) => {
        console.log('Error: ', error);
        return false;
      };
  }

  private arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private arrayBufferToString(buffer) {
    var bytes = new Uint8Array(buffer);
    var enc = new TextDecoder("utf-8");
    return enc.decode(bytes);
  }

  public getImage<T extends JsonApiModel>(
    modelType: ModelType<T>,
    id: string,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const url: string = this.buildUrl(modelType, params, id, undefined, customUrl) + '/show';

    const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'arraybuffer', observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          // console.log('HttpResponse Content-Type', res.headers.get('Content-Type'));

          let data: any;
          const documentContentType = res.headers.get('Content-Type');
          switch (documentContentType) {
            case 'image/jpeg':
              data = 'data:' + documentContentType + ';base64,' + this.arrayBufferToBase64(res.body);
              break;

            case 'image/svg':
              data = this.arrayBufferToString(res.body);
              break;

            default:
              data = 'data:' + documentContentType + ';base64,' + this.arrayBufferToBase64(res.body);

          }

          return data;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public addImage<T extends JsonApiModel>(
    modelType: ModelType<T>,
    data: any,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const typeName: string = 'documents';
    const url: string = this.buildUrl(modelType, params, undefined, undefined, customUrl) + '/create';
    const requestOptions: object = this.buildRequestOptions({ headers });
    const body: any = {
      data: {
        type: typeName,
        attributes: data
      }
    }

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          // console.log('HttpResponse Content-Type', res.headers.get('Content-Type'));

          return data;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getImageAsBase64(imageId, headers?: HttpHeaders) {
    // tslint:disable-next-line: max-line-length
    const url: string = environment.api.base_url.replace(/\/+$/, '') + '/' + environment.api.version + '/documents/' + imageId + '/show-base64';
    const requestOptions: object = this.buildRequestOptions({ headers });
    // console.log(url);
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }


  public getDocument(
    id: string,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const modelType = DocumentModel;
    const url: string = this.buildUrl(modelType, params, id, undefined, customUrl) + '/show';

    const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'arraybuffer', observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          console.log('HttpResponse', res);
          // console.log('HttpResponse Content-Type', res.headers.get('Content-Type'));
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getDocumentByUrl(
    url: string,
    params?: any,
    headers?: HttpHeaders
  ): Observable<any> {
    const modelType = DocumentModel;

    const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'arraybuffer', observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          console.log('HttpResponse', res);
          // console.log('HttpResponse Content-Type', res.headers.get('Content-Type'));
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

}
