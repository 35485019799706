import { Component, Input, Output, EventEmitter, ElementRef, ViewEncapsulation, ViewChild, OnChanges, PLATFORM_ID, Inject, SecurityContext, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';
import { TaskService, TaskModel, TaskHistoryModel } from 'src/app/core/services/tasks.service';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
// import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate, formatRelative } from 'date-fns';

const defaultItem = {};

@Component({
  selector: 'n7-task-preview',
  templateUrl: './n7-task-preview.component.html',
  styleUrls: [
    './n7-task-preview.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class N7TaskPreviewComponent implements OnChanges {
  public item: any;
  public itemImage: string;
  public itemImageCounter: number = 0;
  public itemType: string;
  public itemStyle: string = 'default';
  public taskCircleIcons: any;


  public access_token: string;

  _task: any = {};
  _selectionMode = false;
  _selected = false;
  taskMainIcon = '';
  taskMinorIcon = '';

  constructor(
    private translateService: TranslateService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private taskService: TaskService,
    private domSanitizer: DomSanitizer
  ) {
    this.taskCircleIcons = {
      draft: '/assets/pins/Draft/Circle.svg',
      submitted: '/assets/pins/Sent/Circle.svg',
      'closed-submitted': '/assets/pins/Sent/Circle.svg',
      accepted: '/assets/pins/Accepted/Circle.svg',
      'closed-accepted': '/assets/pins/Accepted/Circle.svg',
      'closed-denied': '/assets/pins/Closed/Declined/Circle.svg',
      denied: '/assets/pins/Declined/Circle.svg',
      'in progress': '/assets/pins/Progress/Circle.svg',
      'closed-in progress': '/assets/pins/Progress/Circle.svg',
      completed: '/assets/pins/Completed/Circle.svg',
      'closed-completed': '/assets/pins/Closed/Completed/Circle.svg',
      open: '/assets/pins/Remark/Open/Circle.svg',
      'closed-open': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      no_actions: '/assets/pins/Remark/NoActions/Circle.svg',
      'closed-no_actions': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      local_actions: '/assets/pins/Remark/Local/Circle.svg',
      'closed-local_actions': '/assets/pins/Remark/Local/Closed/Circle.svg',
      total_actions: '/assets/pins/Remark/Total/Circle.svg',
      'closed-total_actions': '/assets/pins/Remark/Total/Closed/Circle.svg',
      monitored: '/assets/pins/Monitored/Circle.svg',
      'closed-monitored': '/assets/pins/Monitored/Circle.svg',
    };
  }

  @Input() set theme(val: string) {
    this.itemStyle = ((val !== undefined && val !== null) && (val === 'lite')) ? val : 'default';
  }

  @Input() set taskItem(val: any) {
    this.item = (val !== undefined && val !== null) ? val : defaultItem;
  }


  @Input() set token(val: string) {
    this.access_token = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set task(val: string) {
    this._task = (val !== undefined && val !== null) ? val : {};
  }

  @Input() set selectionMode(val: boolean) {
    this._selectionMode = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set selected(val: boolean) {
    this._selected = (val !== undefined && val !== null) ? val : false;
  }

  @Output() onClick = new EventEmitter();

  ngOnChanges() {
    // this.item.lastupdate_text = this.dateToFromNowDaily(this.item['updated-at']);
    this.item.create_text = formatDate(this.item['created-at'], 'dd.MM.yyyy HH:mm');
    this.item.lastupdate_text = formatDate(this.item['updated-at'], 'dd.MM.yyyy HH:mm');

    // Author Initials
    if (this.item.author) {
      let authorSplit = this.item.author.split(' ');
      this.item.author_initials = authorSplit[0].charAt(0).toUpperCase() + authorSplit[1].charAt(0).toUpperCase();
    }
    else {
      this.item.author_initials = '';
    }

    // Last Editor Initials
    if (this.item.last_editor) {
      let lastEditorSplit = this.item.last_editor.split(' ');
      this.item.last_editor_initials = lastEditorSplit[0].charAt(0).toUpperCase() + lastEditorSplit[1].charAt(0).toUpperCase();
    }
    else {
      this.item.last_editor_initials = '';
    }


    if (this.access_token) {
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.access_token });
      this.taskService.headers = headers;
    }

    let lastHistory = null;
    if (this.item.last_history) {
      lastHistory = this.item.last_history;
    }
    else {
      if (
        this.item.attributes &&
        this.item.attributes.last_history
      ) {
        lastHistory = this.item.attributes.last_history;
      }
    }

    if (
      lastHistory &&
      lastHistory.attributes &&
      lastHistory.attributes.photos &&
      lastHistory.attributes.photos.data
    ) {
      this.itemImageCounter = 0;
      if (lastHistory.attributes.photos.data.detailed_images &&
        lastHistory.attributes.photos.data.detailed_images[0] &&
        lastHistory.attributes.photos.data.detailed_images[0].attributes &&
        lastHistory.attributes.photos.data.detailed_images[0].attributes.base64
      ) {
        this.itemImageCounter += lastHistory.attributes.photos.data.detailed_images.length;
        let trustUrl = null;
        const imageData = lastHistory.attributes.photos.data.detailed_images[0].attributes.base64;
        trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
        this.itemImage = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
      }

      if (lastHistory.attributes.photos.data.additional_images &&
        lastHistory.attributes.photos.data.additional_images[0] &&
        lastHistory.attributes.photos.data.additional_images[0].attributes &&
        lastHistory.attributes.photos.data.additional_images[0].attributes.base64
      ) {
        this.itemImageCounter += lastHistory.attributes.photos.data.additional_images.length;
        if (!this.itemImage) {
          let trustUrl = null;
          const imageData = lastHistory.attributes.photos.data.additional_images[0].attributes.base64;
          trustUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageData);
          this.itemImage = this.domSanitizer.sanitize(SecurityContext.URL, trustUrl);
        }
      }
    }


    this._renderer.removeClass(this._elementRef.nativeElement, 'selected');
    // console.log('this._task.status', this._task.status);
    if (this._task.is_open) {
      switch (this._task.status) {
        case 'submitted':
          this.taskMainIcon = '/assets/pins/Sent/Active.svg';
          this.taskMinorIcon = '/assets/pins/Sent/Micro.svg';
          break;

        case 'accepted':
          this.taskMainIcon = '/assets/pins/Accepted/Active.svg';
          this.taskMinorIcon = '/assets/pins/Accepted/Micro.svg';
          break;

        case 'completed':
          this.taskMainIcon = '/assets/pins/Completed/Active.svg';
          this.taskMinorIcon = '/assets/pins/Completed/Micro.svg';
          break;

        case 'draft':
          this.taskMainIcon = '/assets/pins/Draft/Active.svg';
          this.taskMinorIcon = '/assets/pins/Draft/Micro.svg';
          break;

        case 'in progress':
          this.taskMainIcon = '/assets/pins/Progress/Active.svg';
          this.taskMinorIcon = '/assets/pins/Progress/Micro.svg';
          break;

        case 'monitored':
          this.taskMainIcon = '/assets/pins/Monitored/Active.svg';
          this.taskMinorIcon = '/assets/pins/Monitored/Micro.svg';
          break;

        case 'remarked':
          this.taskMainIcon = '/assets/pins/Remark/Active.svg';
          this.taskMinorIcon = '/assets/pins/Remark/Micro.svg';
          break;

        case 'denied':
          this.taskMainIcon = '/assets/pins/Declined/Active.svg';
          this.taskMinorIcon = '/assets/pins/Declined/Micro.svg';
          break;

        default:
          this.taskMainIcon = '/assets/pins/Draft/Active.svg';
          this.taskMinorIcon = '/assets/pins/Draft/Micro.svg';
      }
    }
    else {
      switch (this._task.status) {
        case 'completed':
          this.taskMainIcon = '/assets/pins/Closed/Completed/Active.svg';
          this.taskMinorIcon = '/assets/pins/Closed/Completed/Micro.svg';
          break;

        case 'monitored':
          this.taskMainIcon = '/assets/pins/Monitored/Active.svg';
          this.taskMinorIcon = '/assets/pins/Monitored/Micro.svg';
          break;

        case 'denied':
          this.taskMainIcon = '/assets/pins/Closed/Declined/Active.svg';
          this.taskMinorIcon = '/assets/pins/Closed/Declined/Micro.svg';
          break;

        default:
          this.taskMainIcon = '/assets/pins/Closed/Declined/Active.svg';
          this.taskMinorIcon = '/assets/pins/Closed/Declined/Micro.svg';
      }
    }

    if (this._selectionMode) {
      this._renderer.addClass(this._elementRef.nativeElement, 'selected');
    }
  }

  onClickEvent(task: any) {
    this.onClick.emit(task);
  }

  defaultAction() {
    this.onClick.emit(this.item);
  }

  dateToFromNowDaily(customDate) {
    // get from-now for this date
    // var fromNow = moment(customDate).fromNow();

    const result = formatRelative(customDate, new Date());
    // ensure the date is displayed with today and yesterday
    return result;
    // return moment(customDate).calendar(null, {
    //   // when the date is closer, specify custom values
    //   lastWeek: '[Last] dddd [at] HH:mm',
    //   lastDay: '[Yesterday] [at] HH:mm',
    //   sameDay: '[Today] [at] HH:mm',
    //   nextDay: '[Tomorrow] [at] HH:mm',
    //   nextWeek: 'dddd [at] HH:mm',
    //   // when the date is further away, use from-now functionality             
    //   sameElse: function () {
    //     return "[" + fromNow + "]";
    //   }
    // });
  }

}
