import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-st-popover-actions',
  templateUrl: './st-popover-actions.component.html',
  styleUrls: ['./st-popover-actions.component.scss'],
})
export class StPopoverActionsComponent implements OnInit {

  options;
  constructor( public popoverController: PopoverController,) { }

  ngOnInit() {}
  onClick(option){
    this.popoverController.dismiss(
      {
        optionSelected: option.key,
      }
    );
  }
  cancel(){
    this.popoverController.dismiss();
  }
}
