import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromProjects from "./projects.actions";
import { Project } from './projects.model';

export interface ProjectsState {
  entities: Project[];
  isSending: boolean;
  error: string | any;
}

export const initialState: ProjectsState = {
  entities: [],
  isSending: false,
  error: null
};

export function reducer(
  state = initialState,
  action: fromProjects.ProjectsActions
): ProjectsState {

  switch (action.type) {
    case fromProjects.ActionTypes.CleanAllProjects: {
      return initialState;
    }

    case fromProjects.ActionTypes.AddProject: {
      return {
        ...state,
        entities: [...state.entities, action.payload.data]
      };
    }

    case fromProjects.ActionTypes.RemoveProject: {
      return {
        ...state,
        entities: state.entities.filter(item => item.id !== action.payload.data),
      };
    }

    default: {
      return state;
    }
  }
}
export const getProjectsState = createFeatureSelector<ProjectsState>('projects');

export const selectAllProjects = createSelector(getProjectsState, (state) => (state.entities));

export const selectProjectByID = (id: string) => createSelector(
  selectAllProjects,
  (allProjects) => allProjects.find(Project => Project.id === id)
);

export const selectAllProjectsByBoatID = (id: string) => createSelector(
  selectAllProjects,
  (allProjects) => allProjects.filter(Project => Project.boat_id === id)
);

// export const getLoading = (state: ProjectsState) => state.loading;

export const getError = (state: ProjectsState) => state.error;
