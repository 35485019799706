import { UserService } from 'src/app/core/services/user.service';
import { User } from './../../state/user/user.model';
import { UpdatesModel, UpdatesSevice } from './../../core/services/notifications.service';
import { Component, OnInit, Input } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
})
export class NotifyComponent implements OnInit {
  constructor(
    private notifyservice: UpdatesSevice,
    private userservice: UserService,
    private router: Router
  ) { }
  @Input() notify: UpdatesModel;

  public class = '';
  private accesstoken;
  public author = '';
  public authorInitials = '';
  ngOnInit() {

    if (!this.notify['is-read']) {
      this.class = 'unread';
    }
    // console.log(this.notify);
    if (this.notify.author) {
      const first  = (this.notify.author.name) ? this.notify.author.name : '';
      const second = (this.notify.author.surname) ? this.notify.author.surname : '';
      if (first !== '' && second !== '' ) {
        this.author = first.charAt(0).toUpperCase()  +  second.charAt(0).toUpperCase() ;
      }
      if (first !== '' && second === '' ) {
        this.author = first.charAt(2).toUpperCase() ;
      }
      if (first === '' && second !== '' ) {
        this.author = second.charAt(2).toUpperCase() ;
      }
      this.authorInitials = first.charAt(0).toUpperCase() + second.charAt(0).toUpperCase();
      this.authorInitials = (this.authorInitials !== '') ? this.authorInitials : '!';
    }
    else {
      this.author = '-';
      this.authorInitials = '!';
    }
  }

  markAsRead(notify: UpdatesModel) {
    if (!this.notify['is-read']) {
      this.notify['is-read'] = true;
      this.class = '';
      this.userservice.getToken().pipe(
        take(1)
      ).subscribe(accesstoken => {
          this.accesstoken = accesstoken;
          const headers = new HttpHeaders({Authorization: 'Bearer ' + this.accesstoken});
          this.notifyservice.headers = headers;
          // console.log();
          this.notifyservice.markAsRead(this.notify['update-id']).subscribe( data => {
          });
      });
    }
  }

  viewDetailOnMap(notify: UpdatesModel) {
    // console.log(this.notify);
    this.markAsRead(notify);
    const route = 'boat/' + this.notify['boat-id'] + '/project/' + this.notify['project-id'] + '/yacht/control';
    this.router.navigate([route], { queryParams: { project: this.notify['project-id'], task: this.notify['task-id'] } });
  }
}
