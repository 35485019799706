import { Component, OnInit, Input } from '@angular/core';
import { ProjectModel } from 'src/app/core/services/projects.service';

@Component({
  selector: 'app-projecthistory',
  templateUrl: './projecthistory.component.html',
  styleUrls: ['./projecthistory.component.scss'],
})
export class ProjecthistoryComponent implements OnInit {

  constructor() { }
  @Input() project: ProjectModel;

  ngOnInit() {}

}
