import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';

@JsonApiModelConfig({
  type: 'companies'
})
export class CompanyModel extends JsonApiModel {
  @Attribute()
  company_business_name: string;

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}

const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    boats: CompanyModel
  }
}

@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class CompaniesService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }
}
