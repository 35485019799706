import { Component, Output, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import * as _ from 'lodash';
// import * as moment from 'moment';

const defaultItem = {};

@Component({
  selector: 'n7-task-modal',
  templateUrl: './n7-task-modal.component.html',
  styleUrls: [
    './n7-task-modal.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class N7TaskModalComponent implements OnChanges {
  public item: any = null;
  public history: any = null;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.item = this.navParams.get('item');
    console.log('item', this.item);

    this.history = this.navParams.get('history');
  }

  // @Input() set item(val: any) {
  //   this.task = (val !== undefined && val !== null) ? val : null;
  // }

  @Output() itemClick = new EventEmitter();

  ngOnChanges() { }

  onTaskClick($event) {
    console.log('event', $event);
    // this.itemClick.emit($event);
  }

  dismiss() {
    this.modalController.dismiss({});
  }
}
