import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { N7ModalFormComponent } from '../n7-modal-form/n7-modal-form.component'
import * as _ from 'lodash';

export interface componentInteraction {
  remove(index: number);
}

export interface N7DropDownOptions {
  key: string,
  label: string
}

@Component({
  selector: 'n7-drop-down',
  templateUrl: './n7-drop-down.component.html',
  styleUrls: ['./n7-drop-down.component.scss'],
})
export class N7DropDownComponent implements OnChanges {
  public index: number;
  public name: string;
  public selfRef: N7DropDownComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemIsOpen: boolean;
  public itemDisabled: boolean;
  public itemHasClearOption: boolean;
  public itemLabelClearOption: string = 'No selection';
  public itemHasAddNew: boolean;
  public itemHasBackdrop: boolean;
  public itemHasError: boolean;
  public itemError: string;
  public itemValue: string;
  public itemTitle: string;
  public itemOptions: N7DropDownOptions[];
  public itemHint: string;

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set isOpen(val: boolean) {
    this.itemIsOpen = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hasClearOption(val: boolean) {
    this.itemHasClearOption = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set labelClearOption(val: string) {
    this.itemLabelClearOption = (val !== undefined && val !== null) ? val : 'No selection';
  }

  @Input() set hasAddNew(val: boolean) {
    this.itemHasAddNew = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hasBackdrop(val: boolean) {
    this.itemHasBackdrop = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set value(val: string) {
    this.itemValue = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set options(val: []) {
    this.itemOptions = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set title(val: string) {
    this.itemTitle = (val !== undefined && val !== null) ? val : 'Choose';
  }

  @Input() set disabled(val: boolean) {
    this.itemDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Output() valueChange = new EventEmitter()

  constructor(
    public modalController: ModalController
  ) {}

  ngOnChanges() {}

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  getLabelFromKey(key: string) {
    let label = '';
    const index = _.findIndex(this.itemOptions,(o) => { return o.key === key; });
    if (index >= 0) {
      label = this.itemOptions[index].label;
    }
    return ( label ) ? label : this.itemTitle;
  }

  onItemToggle(event) {
    this.itemIsOpen = !this.itemIsOpen;
    // this.valueChange.emit(this.itemValue);
  }

  onItemSelected(key: string) {
    const index = _.findIndex(this.itemOptions,(o) => { return o.key === key; });
    if (index >= 0) {
      this.itemIsOpen = false;
      this.itemValue = key;
      this.valueChange.emit(this.itemValue);
    }
    else {
      this.itemIsOpen = false;
    }
  }

  onItemClear() {
    this.itemIsOpen = false;
    this.itemValue = null;
    this.valueChange.emit(this.itemValue);
  }

  onItemAdd() {
    this.presentNewLocationModal({});
  }

  async presentNewLocationModal(ev: any) {
    const nameFormItem = {
      type: 'text',
      label: 'Name',
      placeholder: 'Type something...',
      unit: '',
      value: '',
      hint: ''
    };
    const modal = await this.modalController.create({
      component: N7ModalFormComponent,
      componentProps: {
        titleLabel: 'New Location',
        formItems: [nameFormItem],
        dismissLabel: 'Save'
      },
      cssClass: 'modal-form'
    });

    modal.onDidDismiss().then((data) => {
      this.itemIsOpen = false;
      if (data !== null) {
        if (data.data && data.data.formItems && data.data.formItems[0] && data.data.formItems[0].value && data.data.formItems[0].value.length) {
          this.itemValue = data.data.formItems[0].value
          this.itemOptions.push({
            key: data.data.formItems[0].value,
            label: data.data.formItems[0].value
          });
          this.valueChange.emit(this.itemValue);
        }
      }

    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  onItemChange(event) {
    this.valueChange.emit(this.itemValue);
  }

  onKeyUp(event) {
    this.valueChange.emit(this.itemValue);
  }
}
