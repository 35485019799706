import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams, HttpEvent } from '@angular/common/http';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';
import { Utilities } from 'src/common/utilities.class';
import { catchError, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Company } from './company.service';


@JsonApiModelConfig({
  type: 'resources'
})
export class ResourceModel extends JsonApiModel {
  @Attribute()
  avg_hours_tracked_per_day: number;

  @Attribute()
  badge_qr_code: string;

  @Attribute()
  birth_date: string;

  @Attribute()
  birth_place: string;

  @Attribute()
  birth_province: string;

  @Attribute()
  business_name: string;

  @Attribute()
  cf: string;

  @Attribute()
  company_id: number;

  @Attribute()
  cost_per_hour: number; // Es: 11,00

  @Attribute()
  created_at: Date;

  @Attribute()
  email: string;

  @Attribute()
  gender: string;

  @Attribute()
  hourly_pay: number; // Es: 11,00

  @Attribute()
  is_pm: boolean;

  @Attribute()
  is_team_leader: boolean;

  @Attribute()
  is_worker: boolean;

  @Attribute()
  middlename: string;

  @Attribute()
  name: string;

  @Attribute()
  nationality: string;

  @Attribute()
  nationality_code: string;

  @Attribute()
  notes: string;

  @Attribute()
  p_iva: string;

  @Attribute()
  serial_number: number;

  @Attribute()
  site_id: number;

  @Attribute()
  skills: string[];

  @Attribute()
  suffix: string;

  @Attribute()
  surname: string;

  @Attribute()
  updated_at: Date;

  @Attribute()
  user_id: number;
}
const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    resources: ResourceModel
  }
}

/**
 * Resource interface
 */
export interface Resource {
  password: any;
  email: any;
  id: string,
  badgeUrl: string,
  photoUrl: string,
  name: string,
  surname: string,
  company: string,
  role: string,
  costHourly: number,
  dailyAvg: number,
  team: string,
  serialNumber: string,
  shipyard: string,
  skills: string[],
}

/**
 * ResourceService
 */
@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class ResourceService extends JsonapiService {
  private resources: Resource[] = [];
  private companies: Company[] = [];
  token: string;

  /**
   * @ignore
   */
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Retrieve the companies.
   * 
   * @returns 
   */
  public getCompanies() {
    return new Promise<Company[]>(async (resolve, reject) => {
      try {
        // TODO - Recuperare via API
        const companies = this.companies;
        await Utilities.waitFor(500);
        resolve(companies);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Retrieve the resources.
   * 
   * @returns 
   */
  public getResources() {
    return new Promise<Resource[]>(async (resolve, reject) => {
      try {
        let filters = {
          // exclude_opener_application_log_id: documentId
        };
        const params = {
          filter: filters,
          // sort: '-id'
          // sort: '-updated_at'
        };
        this.getCollection(ResourceModel, params).pipe(take(1)).subscribe(async remoteResources => {
          // console.log('remoteResources', remoteResources);
          const resources = [];
          await Utilities.asyncForEach(remoteResources, remoteResource => {
            const resource = {
              id: remoteResource.id,
              email: remoteResource.email,
              badgeUrl: (remoteResource.badge_qr_code && ('no_file' !== remoteResource.badge_qr_code)) ? remoteResource.badge_qr_code : '',
              photoUrl: (remoteResource.meta.image) ? remoteResource.meta.image : '',
              name: remoteResource.name,
              surname: remoteResource.surname,
              company: remoteResource.company_id,
              role: (remoteResource.is_pm) ? 'PM' : ((remoteResource.is_team_leader) ? 'TL' : 'W'),
              costHourly: remoteResource.cost_per_hour,
              dailyAvg: remoteResource.avg_hours_tracked_per_day,
              team: '',
              shipyard: remoteResource.site_id,
              serialNumber: remoteResource.serial_number,
              skills: remoteResource.skills,
            };
            resources.push(resource);
          });
          resolve(resources);
        });

        // const resources = this.resources;
        // await Utilities.waitFor(500);
        // resolve(resources);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Create the resource.
   * 
   * @returns 
   */
  public createResource(value: Resource) {
    return new Promise<Resource>(async (resolve, reject) => {
      try {
        // console.log('resource', value);

        const data = {
          serial_number: value.serialNumber,
          company_id: value.company,
          cost_per_hour: value.costHourly,
          name: value.name,
          site_id: value.shipyard,
          surname: value.surname,
          role: value.role,
          skills: value.skills,
          // is_pm: ('PM' === value.role),
          // is_team_leader: ('TL' === value.role),
          // is_worker: ('W' === value.role),
        };
        if (value.email) {
          data['email'] = value.email;
        }
        if (value.password) {
          data['password'] = value.password;
        }
        // console.log('data', data);
        const resource = this.createEntity(ResourceModel, data);
        this.addEntity(resource).pipe(
          take(1)
        ).subscribe(async (resourceData) => {
          // console.log('resourceData', resourceData);
          value.id = resourceData.data.id;
          resolve(value);
        },
          (err) => {
            if (err && err.error && err.error.errors && err.error.errors.title && err.error.errors.detail) {
              console.log('err', err.error.errors);
            }
            else {
              console.log('err', err);
            }
            reject();
          });
        // resolve(value);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Update the resource.
   * 
   * @returns 
   */
  public updateResource(resourceId: string, value: Resource) {
    return new Promise<Resource>(async (resolve, reject) => {
      try {
        // console.log('resource', value);

        const data: any = {
          company_id: value.company,
          cost_per_hour: value.costHourly,
          // dailyAvg: value.dailyAvg,
          name: value.name,
          site_id: value.shipyard,
          surname: value.surname,
          role: value.role,
          serial_number: value.serialNumber,
          skills: value.skills,
        };
        if (value.password) {
          // console.log('updating password');
          data.password = value.password;
        }
        // console.log('data', data);
        const resource = this.createEntity(ResourceModel, data);
        this.updateEntity(resourceId, resource).pipe(
          take(1)
        ).subscribe(async (resourceData) => {
          // console.log('resourceData', resourceData);
          resolve(value);
        },
          (err) => {
            if (err && err.error && err.error.errors && err.error.errors.title && err.error.errors.detail) {
              console.log('err', err.error.errors);
            }
            else {
              console.log('err', err);
            }
            reject();
          });
        // resolve(value);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Delete the resource.
   * 
   * @returns 
   */
  public deleteResource(resourceId: string) {
    return new Promise(async (resolve, reject) => {
      try {

        this.deleteEntity(ResourceModel, resourceId).pipe(
          take(1)
        ).subscribe(async (resourceData) => {
          // console.log('resourceData', resourceData);
          resolve(true);
        },
          (err) => {
            if (err && err.error && err.error.errors && err.error.errors.title && err.error.errors.detail) {
              console.log('err', err.error.errors);
            }
            else {
              console.log('err', err);
            }
            reject();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  public getCustomUrlRequest(url: string, headers?: HttpHeaders, responseType?: string, observe?: string) {
    const options = {
      headers, responseType, observe
    }
    const requestOptions: object = this.buildRequestOptions(options);
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public postCustomUrlRequest(url: string, data: any, headers?: HttpHeaders, responseType?: string, observe?: string) {
    const options = {
      headers, responseType, observe
    }
    const requestOptions: object = this.buildRequestOptions(options);
    return this.http.post(url, data, requestOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  /**
   * Generate resource badge
   * 
   * @param resourceId 
   * @returns 
   */
  public generateBadge(resourceId: string, headers?: HttpHeaders) {
    return new Promise(async (resolve, reject) => {
      try {
        const url: string = environment.api.base_url.replace(/\/+$/, '') + '/' + environment.api.version + '/resources/' + resourceId + '/generate-badge';
        this.getCustomUrlRequest(url, headers).pipe(
          take(1)
        ).subscribe((res: any) => {
          // console.info(res);
          resolve(true);
        }, (error) => {
          // console.error(error);
        }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Download resource badge
   * 
   * @param resourceId 
   * @returns 
   */
  public downloadBadge(resourceId: string, headers?: HttpHeaders) {
    return new Promise(async (resolve, reject) => {
      try {
        const url: string = environment.api.base_url.replace(/\/+$/, '') + '/' + environment.api.version + '/resources/' + resourceId + '/download-badge-pdf';
        this.getCustomUrlRequest(url, headers, 'arraybuffer', 'response').pipe(
          take(1)
        ).subscribe((res: any) => {
          // console.info(res);
          resolve(res);
        }, (error) => {
          // console.error(error);
        }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Download resource badge
   * 
   * @param resourceId 
   * @returns 
   */
  public downloadBadges(resourceIds: any, headers?: HttpHeaders) {
    return new Promise(async (resolve, reject) => {
      try {
        const url: string = environment.api.base_url.replace(/\/+$/, '') + '/' + environment.api.version + '/resources/download-badges-pdf';
        this.postCustomUrlRequest(url, resourceIds, headers, 'arraybuffer', 'response').pipe(
          take(1)
        ).subscribe((res: any) => {
          // console.info(res);
          resolve(res);
        }, (error) => {
          // console.error(error);
        }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  public createFromCSV(csvFile: File, headers?: HttpHeaders) {
    return new Promise(async (resolve, reject) => {
      try {
        const url: string = environment.api.auth_base_url.replace(/\/+$/, '') + '/create-resources-from-csv';
        this.sendFile(url, csvFile).pipe(
          take(1)
        ).subscribe((res: any) => {
          console.info(res);
          resolve(res);
        }, (error) => {
          // console.error(error);
          reject(error);
        }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  public sendFile(url: string, fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    const customHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Accepted-Encoding': 'application/json'
    });

    const customOptions = {
      headers: customHeaders,
      reportProgress: true
    };

    return this.http.post(url, formData, customOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      )
  }

  public downloadSampleCSV(headers?: HttpHeaders) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const url: string = environment.api.auth_base_url.replace(/\/+$/, '') + '/download-csv-sample-file';

        this.getCustomUrlRequest(url, headers, 'text').pipe(
          take(1)
        ).subscribe((res: any) => {
          // console.info(res);
          resolve(res);
        }, (error) => {
          // console.error(error);
        }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  public getTimereportCSV(
    resourceId: string,
    filename: string,
    headers?: HttpHeaders
  ) {
    const url: string = this.buildUrl(ResourceModel, null, resourceId, null, null) + '/download-csv';
    const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'text', observe: 'response' });
    let body = {
      'filename': filename
    };

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );

  }
}
