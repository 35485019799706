import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-st-work-step',
  templateUrl: './st-work-step.component.html',
  styleUrls: ['./st-work-step.component.scss'],
})
export class StWorkStepComponent implements OnInit {

  // used for graphics layout 
  isMobileSize: boolean

  itemIsUnavailable: boolean;
  itemIsActive: boolean;
  itemTitle: string;
  itemSubtitle: string;
  itemList;

  @Input() set isUnavailable(val: boolean) {
    this.itemIsUnavailable = val !== undefined && val !== null ? val : false;
  }
  @Input() set isActive(val: boolean) {
    this.itemIsActive = val !== undefined && val !== null ? val : false;
  }
  @Input() set title(val: string) {
    this.itemTitle = val !== undefined && val !== null ? val : '';
  }
  @Input() set subtitle(val: string) {
    this.itemSubtitle = val !== undefined && val !== null ? val : '';
  }
  @Input() set list(val: any) {
    this.itemList = val !== undefined && val !== null ? val : [];
  }

  constructor() { }

  ngOnInit() {
    if (window.innerWidth > 768)
      this.isMobileSize = false;
    else this.isMobileSize = true;
  }

}
