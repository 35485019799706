// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  box-sizing: border-box;
  padding: 8px 16px;
  border: 2px solid transparent;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 18px;
  letter-spacing: -0.32px;
  text-align: center;
  text-decoration: none;
  background-color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/n7-simple-item/n7-simple-item.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,iBAAA;EACA,6BAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,qBAAA;EACA,0CAAA;AACJ","sourcesContent":["button {\n    box-sizing: border-box;\n    padding: 8px 16px;\n    border: 2px solid transparent;\n    border-radius: 5px;\n    color: #FFFFFF;\n    font-size: 18px;\n    letter-spacing: -0.32px;\n    text-align: center;\n    text-decoration: none;\n    background-color: var(--ion-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
