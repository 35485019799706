import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface componentInteraction {
  remove(index: number);
  onItemChange(index: number, itemData: N7ItemZoneLog);
}

export interface N7ItemZoneLog {
  id: string,
  name: string,
  location: string,
  file: string
}

@Component({
  selector: 'n7-item-zone-log',
  templateUrl: './n7-item-zone-log.component.html',
  styleUrls: ['./n7-item-zone-log.component.scss'],
})
export class N7ItemZoneLogComponent implements OnChanges {

  @Input() set item(val: N7ItemZoneLog) {
    this.itemData = (val !== undefined && val !== null) ? val : {
      id: '',
      name: '',
      location: '',
      file: ''
    };
    // console.log('itemData', this.itemData);
  }

  @Input() set listOptions(val: []) {
    this.dropDownOptions = (val !== undefined && val !== null) ? val : [];
    // console.log('dropDownOptions', this.dropDownOptions);
  }

  @Input() set listValue(val: string) {
    this.dropDownValue = (val !== undefined && val !== null) ? val : '';
    // console.log('dropDownValue', this.dropDownValue);
  }

  public itemData: N7ItemZoneLog;
  public index: number;
  public name: string;
  public section: any;
  public selfRef: N7ItemZoneLogComponent;

  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;


  public itemLabel: string;

  public dropDownTitle = 'Choose zone';
  public dropDownValue = '';
  public dropDownOptions = [];

  public photos: string[];
  public maxPhotos: number = 6;

  public description: any;

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Output() locationChange = new EventEmitter()

  @Output() fileChange = new EventEmitter()

  constructor() {
  }

  ngOnChanges() {}

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  itemChange(index) {
    if (this.compInteraction) {
      this.compInteraction.onItemChange(index, this.itemData);
    }
  }

  onLocationChange(event) {
    // console.log(event);
    this.dropDownValue = event;
    this.locationChange.emit(this.dropDownValue);
  }

}
