import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface componentInteraction {
  remove(index: number);
  onItemChange(index: number, itemData: N7ItemEnvLog);
}

export interface N7ItemEnvLog {
  id: string,
  name: string,
  location: string,
  file: string
}

@Component({
  selector: 'n7-item-env-log',
  templateUrl: './n7-item-env-log.component.html',
  styleUrls: ['./n7-item-env-log.component.scss'],
})
export class N7ItemEnvLogComponent implements OnChanges {
  // @Input() item: N7ItemEnvLog;

  @Input() set item(val: N7ItemEnvLog) {
    this.itemData = (val !== undefined && val !== null) ? val : {
      id: '',
      name: '',
      location: '',
      file: ''
    };
    // console.log('itemData', this.itemData);
  }

  @Input() set listOptions(val: []) {
    this.dropDownOptions = (val !== undefined && val !== null) ? val : [];
    // console.log('dropDownOptions', this.dropDownOptions);
  }

  @Input() set listValue(val: string) {
    this.dropDownValue = (val !== undefined && val !== null) ? val : '';
    // console.log('dropDownValue', this.dropDownValue);
  }

  public itemData: N7ItemEnvLog;
  public index: number;
  public name: string;
  public section: any;
  public selfRef: N7ItemEnvLogComponent;

  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;


  public itemLabel: string;

  public dropDownTitle = 'Choose location';
  public dropDownValue = '';
  public dropDownOptions = [];

  public fileUploadValue: any;

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Output() locationChange = new EventEmitter()

  @Output() fileChange = new EventEmitter()

  constructor() {
  }

  ngOnChanges() {}

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  itemChange(index) {
    if (this.compInteraction) {
      this.compInteraction.onItemChange(index, this.itemData);
    }
  }

  onLocationChange(event) {
    // console.log(event);
    this.dropDownValue = event;
    this.locationChange.emit(this.dropDownValue);
  }

  onFileChange(event) {
    // console.log(event);
    this.fileUploadValue = event;
    this.fileChange.emit(this.fileUploadValue);
  }

}
