import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';

// tslint:disable-next-line: class-name
export class AutoCompleteElement {
  public id;
  public label;
}

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})


export class AutocompleteComponent implements OnInit {
  constructor() { }

  public input;
  public filteredList = [];
  public selectedItem = null;
  public title: string;

  @Input() list: Array<any>;
  @Input() defaultValue: AutoCompleteElement = null;
  @Input() set label(val: string) {
    this.title = (val !== undefined && val !== null) ? val : '';
  }

  // tslint:disable-next-line: no-output-on-prefix
  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() selectElement: EventEmitter<any> = new EventEmitter();

  ngOnChanges() {
    if (this.defaultValue) {
      this.selectedItem = this.defaultValue.id;
      this.input = this.defaultValue.label;
    }
  }
  ngOnInit() { }

  search() {
    // console.log('search');
    if (!this.input) {
      this.filteredList = [];
      return;
    }
    if (this.input && this.input.length < 3) {
      this.filteredList = [];
      return;
    }
    this.filteredList = [];
    this.list.filter(item => {
      if (item && item.label.toLowerCase().includes(this.input.toLowerCase())) {
        this.filteredList.push(item);
      }
    });
    // console.log('search', this.list, this.input, this.filteredList);
    return;
  }

  public clickEvent() {
    this.addClick.emit();
  }

  add(item) {
    this.selectedItem = item.id;
    this.input = item.label;
    this.filteredList = [];
    this.selectElement.emit(item.id);
  }
}
