import { Action } from "@ngrx/store";
import { Draft } from "./drafts.model";

export enum ActionTypes {
  CleanAllDrafts = "[DRAFTS State] Clean All",

  AddDraft = "[DRAFTS State] Add Draft",
  RemoveDraft = "[DRAFTS State] Remove Draft",
}

export class CleanAllDrafts implements Action {
  readonly type = ActionTypes.CleanAllDrafts;

  constructor() { }
}

export class AddDraft implements Action {
  readonly type = ActionTypes.AddDraft;

  constructor(public payload: { data: Draft }) { }
}

export class RemoveDraft implements Action {
  readonly type = ActionTypes.RemoveDraft;

  constructor(public payload: { data: string | number }) { }
}

export type DraftsActions =
  CleanAllDrafts
  | AddDraft
  | RemoveDraft;
