import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-generate-report',
  templateUrl: './n7-generate-report.component.html',
  styleUrls: ['./n7-generate-report.component.scss'],
})
export class N7GenerateReportComponent implements OnChanges {
  public itemPreviewLabel: string;
  public itemPreviewItems: any[];
  public itemFiltersLabel: string;
  public itemFiltersListItems: any[];
  public itemFiltersListSelectedItems: any[];
  public itemFiltersListMultipleSelection: boolean = false;
  public itemFiltersListShowMore: boolean = false;
  public itemFiltersListMoreLabel: string;
  public itemApplyLabel: string;
  public itemNoApplyLabel: string;
  public itemSubmitLabel: string;

  public itemInfo: string = '';
  public itemCanApply: boolean = false;
  public itemCanSubmit: boolean = false;

  @Input() set previewLabel(val: string) {
    this.itemPreviewLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set previewItems(val: any[]) {
    this.itemPreviewItems = (val !== undefined && val !== null) ? val : [];
  }
  
  @Input() set filtersListShowMore(val: boolean) {
    this.itemFiltersListShowMore = (val !== undefined && val !== null) ? val : false;
  }
  
  @Input() set filtersListMoreLabel(val: string) {
    this.itemFiltersListMoreLabel = (val !== undefined && val !== null) ? val : '';
  }
  
  @Input() set filtersLabel(val: string) {
    this.itemFiltersLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set filtersListItems(val: any[]) {
    this.itemFiltersListItems = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set filtersListSelectedItems(val: any[]) {
    this.itemFiltersListSelectedItems = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set filtersListMultipleSelection(val: boolean) {
    this.itemFiltersListMultipleSelection = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set applyLabel(val: string) {
    this.itemApplyLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set noApplyLabel(val: string) {
    this.itemNoApplyLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set submitLabel(val: string) {
    this.itemSubmitLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Output() submitClick = new EventEmitter()

  @Output() filtersListItemClick = new EventEmitter()

  @Output() filtersListMoreClick = new EventEmitter()

  constructor() {}

  ngOnChanges() {
    // console.log('ngOnChanges');
    if (this.itemFiltersListSelectedItems) {
      this.itemCanApply = (this.itemFiltersListSelectedItems.length > 0);
    }
  }

  isSelected(itemId) {
    const index = _.findIndex(this.itemFiltersListSelectedItems,(o) => { return o === itemId; });
    return (index >= 0);
  }

  scrollTo(id: string) {
    let anchor = document.getElementById(id);
    // console.log('scrollTo', id);
    if (anchor) {
      anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }

  buildInfo() {
    let items = [];
    this.itemFiltersListSelectedItems.forEach(itemId => {
      const index = _.findIndex(this.itemFiltersListItems,(o) => { return o.id === itemId; });
      if (index >= 0) {
        // console.log(this.itemFiltersListItems[index]);
        items.push(this.itemFiltersListItems[index].report_name);
      }
    });
    if (items.length > 1) {
      return items.join(', ') + ' ' + 'selected';
    }
    else if (items.length === 1) {
      return items[0] + ' ' + 'selected';
    }
    else {
      return '';
    }
  }

  onItemFiltersListItemClick(event) {
    // console.log('filtersListItemClick', event);
    this.filtersListItemClick.emit(event);
  }

  onItemFiltersListMoreClick(event) {
    // console.log('filtersListItemClick', event);
    this.filtersListMoreClick.emit(event);
  }

  onItemFiltersActionClick(event) {
    // console.log('onItemFiltersActionClick', event);
    this.itemCanApply = false;

    this.itemInfo = this.buildInfo();
    this.itemCanSubmit = true;
    // this.submitClick.emit();
    this.scrollTo('report-actions');
  }

  onItemSubmitClick(event) {
    // console.log('submitClick', event);
    this.itemCanSubmit = false;
    this.itemInfo = '';
    this.submitClick.emit();
  }

  
}
