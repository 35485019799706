import { Action } from "@ngrx/store";
import { User, Credentials } from "../../state/user/user.model";

export enum ActionTypes {
  LoginUserBegin = "[User Service] Login user begin",
  LoginUserSuccess = "[User Service] Login user Success",
  LoginUserFailure = "[User Service] Login user Failure",

  RefreshTokenBegin = "[User Service] Refresh token begin",
  RefreshTokenSuccess = "[User Service] Refresh token Success",
  RefreshTokenFailure = "[User Service] Refresh token Failure",

  LogoutUserBegin = "[User Service] Logout user begin",
  LogoutUserSuccess = "[User Service] Logout user Success",
  LogoutUserFailure = "[User Service] Logout user Failure",

  GetUserBegin = "[User Service] Get user begin",

  LoadUserBegin = "[User Service] Load user begin",
  LoadUserSuccess = "[User Service] Load user success",
  LoadUserFailure = "[User Service] Load user failure",

  ShowedHint = "[User Service] Showed hint"
}

export class LoginUserBegin implements Action {
  readonly type = ActionTypes.LoginUserBegin;

  constructor(public payload: { data: Credentials }) { }
}

export class LoginUserSuccess implements Action {
  readonly type = ActionTypes.LoginUserSuccess;

  constructor(public payload: { data: any }) { }
}

export class LoginUserFailure implements Action {
  readonly type = ActionTypes.LoginUserFailure;

  constructor(public payload: { error: any }) { }
}

export class RefreshTokenBegin implements Action {
  readonly type = ActionTypes.RefreshTokenBegin;

  constructor(public payload: string) { }
}

export class RefreshTokenSuccess implements Action {
  readonly type = ActionTypes.RefreshTokenSuccess;

  constructor(public payload: { data: any }) { }
}

export class RefreshTokenFailure implements Action {
  readonly type = ActionTypes.RefreshTokenFailure;

  constructor(public payload: { error: any }) { }
}

export class LogoutUserBegin implements Action {
  readonly type = ActionTypes.LogoutUserBegin;

  constructor(public payload: { access_token: string }) { }
}

export class LogoutUserSuccess implements Action {
  readonly type = ActionTypes.LogoutUserSuccess;

  constructor(public payload: { data: any }) { }
}

export class LogoutUserFailure implements Action {
  readonly type = ActionTypes.LogoutUserFailure;

  constructor(public payload: { error: any }) { }
}

export class GetUserBegin implements Action {
  readonly type = ActionTypes.GetUserBegin;
}



export class LoadUserBegin implements Action {
  readonly type = ActionTypes.LoadUserBegin;
}

export class LoadUserSuccess implements Action {
  readonly type = ActionTypes.LoadUserSuccess;

  constructor(public payload: { data: any }) { }
}

export class LoadUserFailure implements Action {
  readonly type = ActionTypes.LoadUserFailure;

  constructor(public payload: { error: any }) { }
}


export class ShowedHint implements Action {
  readonly type = ActionTypes.ShowedHint;

  constructor(public payload: { hint: string }) { }
}

export type ActionsUnion =
  LoginUserBegin
  | LoginUserSuccess
  | LoginUserFailure
  | RefreshTokenBegin
  | RefreshTokenSuccess
  | RefreshTokenFailure
  | LogoutUserBegin
  | LogoutUserSuccess
  | LogoutUserFailure
  | GetUserBegin
  | LoadUserBegin
  | LoadUserSuccess
  | LoadUserFailure
  | ShowedHint;