import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Company, CompanyService } from 'src/app/core/services/company.service';
import { DocumentModel, DocumentsService } from 'src/app/core/services/documents.service';
import { Resource, ResourceService } from 'src/app/core/services/resource.service';
import { Shipyard, ShipyardService } from 'src/app/core/services/shipyard.service';
import { N7ModalConfirmComponent } from '../n7-modal-confirm/n7-modal-confirm.component';
import { N7ModalFormComponent } from '../n7-modal-form/n7-modal-form.component';
import { StModalImportResourcesComponent } from '../st-modal-import-resources/st-modal-import-resources.component';

export interface Option {
  key: string,
  label: string,
}

@Component({
  selector: 'app-st-modal-resource-detail',
  templateUrl: './st-modal-resource-detail.component.html',
  styleUrls: ['./st-modal-resource-detail.component.scss'],
})
export class StModalResourceDetailComponent implements OnInit {
  accessToken: string;
  titleLabel: string;
  nameLabel: string;
  surnameLabel: string;
  companyLabel: string;
  hasBadge: boolean;
  canDownloadBadge: boolean;
  resource: Resource;
  companyOptions: Option[];
  companyOption: Option;
  shipyardOptions: Option[];
  shipyardOption: Option;
  roleOptions: Option[];
  roleOption: Option;
  skills: string[];
  resourceImages: any;
  validResource: boolean;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    public toastController: ToastController,
    private resourceService: ResourceService,
    private documentService: DocumentsService,
    private companyService: CompanyService,
    private shipyardService: ShipyardService,
    private translate: TranslateService
  ) {
    this.accessToken = this.navParams.get('accessToken');
    this.resource = this.navParams.get('resource');
    // console.log(this.resource)
    this.companyOptions = this.navParams.get('companyOptions');
    this.shipyardOptions = this.navParams.get('shipyardOptions');
    this.roleOptions = this.navParams.get('roleOptions');

    this.init();
  }

  ngOnInit() {
    // console.log('this.resource', this.resource);
  }

  init() {
    this.validResource = false;
    this.titleLabel = (this.resource && this.resource.id) ? this.translate.instant('i18n.pages.resource.title.edit', {}) : this.translate.instant('i18n.pages.resource.title.add', {});
    this.nameLabel = (this.resource && this.resource.name) ? this.resource.name : this.translate.instant('i18n.pages.resource.name', {});
    this.surnameLabel = (this.resource && this.resource.surname) ? this.resource.surname : this.translate.instant('i18n.pages.resource.surname', {});

    const resourceCompanyKey = String(this.resource.company)
    const companyOptionIndex = this.companyOptions.findIndex((o) => ((o.key) === (resourceCompanyKey)));
    this.companyOption = (this.resource && this.resource.company && (companyOptionIndex >= 0)) ? this.companyOptions[companyOptionIndex] : null;
    this.companyLabel = (this.companyOption) ? this.companyOption.label : this.translate.instant('i18n.pages.resource.company', {});

    const resourceShipyardKey = String(this.resource.shipyard)
    const shipyardOptionIndex = this.shipyardOptions.findIndex((o) => ((o.key) === (resourceShipyardKey)));
    this.shipyardOption = (this.resource && this.resource.shipyard && (shipyardOptionIndex >= 0)) ? this.shipyardOptions[shipyardOptionIndex] : null;

    // console.log('this.resource', this.resource);
    const resourceRoleKey = String(this.resource.role)
    const roleOptionIndex = this.roleOptions.findIndex((o) => ((o.key) === (resourceRoleKey)));
    this.roleOption = (this.resource && this.resource.role && (roleOptionIndex >= 0)) ? this.roleOptions[roleOptionIndex] : null;

    this.resourceImages = [];
    if (this.resource.photoUrl) {
      this.documentService.getImageAsBase64(this.resource.photoUrl).pipe(
        take(1)
      ).subscribe((document: any) => {
        if (document && document.data && document.data.attributes && document.data.attributes.base64) {
          // console.log('document', document);
          this.resourceImages = [document.data];
        }
        else { }
      });
    }
    this.hasBadge = (this.resource && this.resource.serialNumber && this.resource.serialNumber.length) ? true : false;
    this.canDownloadBadge = (this.resource && this.resource.id && this.resource.serialNumber) ? true : false;
  }

  onTeamSelected($event) {
    // console.log($event);
  }

  onCompanyAdd() {
    // console.log('onCompanyAdd');
    this.presentCompanyAddModal();
  }

  async presentCompanyAddModal() {
    const companyItemTemplate = [
      {
        type: 'text',
        label: 'Business name',
        placeholder: 'Type something...',
        unit: '',
        value: '',
        hint: ''
      },
      {
        type: 'text',
        label: 'Fiscal code',
        placeholder: 'Type something...',
        unit: '',
        value: '',
        hint: ''
      }
    ];

    let companyItem = companyItemTemplate.slice();

    const modal = await this.modalController.create({
      component: N7ModalFormComponent,
      componentProps: {
        titleLabel: 'New company',
        formItems: companyItem,
        dismissLabel: 'save'
      },
      cssClass: 'modal-form'
    });

    modal.onDidDismiss().then(async (data) => {
      if (data !== null) {
        // console.log('Modal data', data);
        if (data.data && data.data.formItems) {
          // Check business name
          const validBusinessName = (data.data.formItems[0] && data.data.formItems[0].value && data.data.formItems[0].value.length);
          // Check fiscal code
          const validFC = (data.data.formItems[1] && data.data.formItems[1].value && data.data.formItems[1].value.length);

          if (validBusinessName && validFC) {
            // console.log('valid');

            const tmpCompany: Company = {
              name: data.data.formItems[0].value,
              fiscalCode: data.data.formItems[1].value
            }
            const newCompany: any = await this.companyService.createCompany(tmpCompany);
            // console.log('newCompany', newCompany);

            if (newCompany && newCompany.id) {
              // console.log('this.companyOptions', this.companyOptions);
              const newOption = {
                key: newCompany.id,
                id: newCompany.id,
                label: newCompany.attributes.company_business_name
              };
              this.companyOptions.push(newOption);
              this.companyOption = newOption;
            }
          }
          else {
            console.log('invalid');
          }
        }
      }
    });

    return await modal.present();
  }

  onCompanySelected($event) {
    // console.log($event);
    if ('string' === typeof $event) {
      const companyOptionIndex = this.companyOptions.findIndex((o) => ((o.key) === ($event)));
      this.companyOption = (this.resource && this.resource.company && (companyOptionIndex >= 0)) ? this.companyOptions[companyOptionIndex] : null;
      this.resource.company = $event;
      this.validate();
    }
    else {
      this.companyOption = $event;
      this.resource.company = this.companyOption.key;
      this.validate();
    }
  }

  onShipyardAdd() {
    // console.log('onShipyardAdd');
    this.presentShipyardAddModal();
  }

  async presentShipyardAddModal() {
    const shipyardItemTemplate = [
      {
        type: 'text',
        label: 'Code',
        placeholder: 'Type something...',
        unit: '',
        value: '',
        hint: ''
      },
      {
        type: 'text',
        label: 'Name',
        placeholder: 'Type something...',
        unit: '',
        value: '',
        hint: ''
      },
      {
        type: 'text',
        label: 'Location',
        placeholder: 'Type something...',
        unit: '',
        value: '',
        hint: ''
      }
    ];

    let shipyardItem = shipyardItemTemplate.slice();

    const modal = await this.modalController.create({
      component: N7ModalFormComponent,
      componentProps: {
        titleLabel: 'New shipyard',
        formItems: shipyardItem,
        dismissLabel: 'save'
      },
      cssClass: 'modal-form'
    });

    modal.onDidDismiss().then(async (data) => {
      if (data !== null) {
        // console.log('Modal data', data);
        if (data.data && data.data.formItems) {
          // Check code
          const validCode = (data.data.formItems[0] && data.data.formItems[0].value && data.data.formItems[0].value.length);
          // Check name
          const validName = (data.data.formItems[0] && data.data.formItems[0].value && data.data.formItems[0].value.length);
          // Check location
          const validLocation = (data.data.formItems[1] && data.data.formItems[1].value && data.data.formItems[1].value.length);

          if (validCode && validName && validLocation) {
            // console.log('valid');
            const tmpShipyard: Shipyard = {
              code: data.data.formItems[0].value,
              name: data.data.formItems[1].value,
              city: data.data.formItems[2].value
            }
            const newShipyard: any = await this.shipyardService.createShipyard(tmpShipyard);
            // console.log('newShipyard', newShipyard);

            if (newShipyard && newShipyard.id) {
              // console.log('this.companyOptions', this.companyOptions);
              const newOption = {
                key: newShipyard.id,
                id: newShipyard.id,
                label: newShipyard.attributes.name
              };
              this.companyOptions.push(newOption);
              this.companyOption = newOption;
            }
            // TODO
            // - Creo l'entità sul DB
            // - Recupero l'ID
            // - Aggiorno la lista
          }
          else {
            console.log('invalid');
          }
        }
      }
    });

    return await modal.present();
  }

  onDockyardSelected($event) {
    // console.log($event);
    // console.log(typeof $event);
    if ('string' === typeof $event) {
      const shipyardOptionIndex = this.shipyardOptions.findIndex((o) => ((o.key) === ($event)));
      // console.log('shipyardOptionIndex', shipyardOptionIndex);
      this.shipyardOption = (this.resource && this.resource.shipyard && (shipyardOptionIndex >= 0)) ? this.shipyardOptions[shipyardOptionIndex] : null;
      this.resource.shipyard = $event;
      this.validate();
    }
    else {
      this.shipyardOption = $event;
      this.resource.shipyard = this.shipyardOption.key;
      this.validate();
    }
    // console.log('this.resource.shipyard', this.resource.shipyard);
  }

  onRoleSelected($event) {
    // console.log($event);
    // console.log(typeof $event);
    if ('string' === typeof $event) {
      const roleOptionIndex = this.roleOptions.findIndex((o) => ((o.key) === ($event)));
      // console.log('roleOptionIndex', roleOptionIndex);
      this.roleOption = (this.resource && this.resource.role && (roleOptionIndex >= 0)) ? this.roleOptions[roleOptionIndex] : null;
      this.resource.role = $event;
      this.validate();
    }
    else {
      this.roleOption = $event;
      this.resource.role = this.roleOption.key;
      this.validate();
    }
  }

  onSkillChanged($event) {
    console.log('onSkillChanged', $event);
    this.resource.skills = $event;
  }

  validate() {
    this.validResource = false;
    if (!this.resource.name) {
      return;
    }
    if (!this.resource.surname) {
      return;
    }
    if (!this.resource.company) {
      return;
    }
    if (!this.resource.shipyard) {
      return;
    }
    if (!this.resource.costHourly) {
      return;
    }
    if (!this.resource.costHourly) {
      return;
    }
    // console.log('resourceImages', this.resourceImages);
    // if (!this.resourceImages.length) {
    //   return;
    // }

    this.validResource = true;
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async submit() {
    this.validResource = false;
    // console.log('submit', this.resource.skills);
    if (this.resource.id) {
      const updatedResource = await this.resourceService.updateResource(this.resource.id, this.resource);
      if (updatedResource && updatedResource.id) {
        this.resource = updatedResource;
        // console.log('this.resourceImages[0]', this.resourceImages[0]);
        if ((this.resourceImages[0]) && ('string' === (typeof this.resourceImages[0].id)) && (this.resourceImages[0].id.startsWith('image__'))) {
          // Salva una nuova immagine solo se ha ID temporaneo.
          // TODO Andrebbero eliminate le altre immagini?
          this.documentService.addImage(DocumentModel,
            {
              'title': 'profile_' + updatedResource.id,
              'file': this.resourceImages[0].attributes.base64,
              'filename': 'profile_' + updatedResource.id + '.jpg',
              'type': 'profile_image',
              'entity_type': 'resource',
              'entity_id': updatedResource.id
            }
          ).subscribe((resp) => {
            // console.log(resp);
          }, (err) => {
            console.log(err);
          }
          );
        }
        const toast = await this.toastController.create({
          message: this.translate.instant('i18n.pages.resource.update.success', {}),
          position: "top",
          cssClass: "st-toast st-toast--success",
          duration: 2000,
        });
        this.init();
        toast.present();
        this.dismiss();
        // this.generateBadge(updatedResource.id);
      }
      else {
        const toast = await this.toastController.create({
          message: this.translate.instant('i18n.pages.resource.update.error', {}),
          position: "top",
          cssClass: "st-toast st-toast--danger",
          duration: 2000,
        });
        toast.present();
      }
    }
    else {
      const newResource = await this.resourceService.createResource(this.resource);
      if (newResource && newResource.id) {
        this.resource = newResource;
        if ((this.resourceImages[0]) && ('string' === (typeof this.resourceImages[0].id)) && (this.resourceImages[0].id.startsWith('image__'))) {
          this.documentService.addImage(DocumentModel,
            {
              'title': 'profile_' + newResource.id,
              'file': this.resourceImages[0].attributes.base64,
              'filename': 'profile_' + newResource.id + '.jpg',
              'type': 'profile_image',
              'entity_type': 'resource',
              'entity_id': newResource.id
            }
          ).subscribe((resp) => {
            console.log(resp);
          }, (err) => {
            console.log(err);
          }
          );
        }
        const toast = await this.toastController.create({
          message: this.translate.instant('i18n.pages.resource.insert.success', {}),
          position: "top",
          cssClass: "st-toast st-toast--success",
          duration: 2000,
        });
        this.init();
        toast.present();
        this.dismiss();
        // this.generateBadge(newResource.id);
      }
      else {
        const toast = await this.toastController.create({
          message: this.translate.instant('i18n.pages.resource.insert.error', {}),
          position: "top",
          cssClass: "st-toast st-toast--danger",
          duration: 2000,
        });
        toast.present();
      }
    }
  }

  delete() {
    this.presentItemRemoveConfirmModal();
  }

  async presentItemRemoveConfirmModal() {
    const modal = await this.modalController.create({
      component: N7ModalConfirmComponent,
      componentProps: {
        titleLabel: 'Delete resource',
        message: 'Do you want delete this resource?',
        dismissLabel: 'Cancel',
        confirmLabel: 'Yes, delete'
      },
      cssClass: 'modal-confirm'
    });

    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && data.data.confirmed) {
          // console.log('data.data.confirmed', data.data.confirmed);
          this.modalController.dismiss({
            'dismissed': true,
            'delete': this.resource.id
          });
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  async generateBadge(resourceId: string) {
    await this.resourceService.generateBadge(resourceId);
  }

  async downloadBadge() {
    // console.log('downloadBadge', this.resource.id);
    // await this.resourceService.generateBadge(this.resource.id);

    // let fileMIMEType = 'image/png';
    // let fileMIMEType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    let fileMIMEType = 'application/pdf';
    const badge: any = await this.resourceService.downloadBadge(this.resource.id);
    // console.log('badge', badge);
    // console.log('badge body', badge.body);

    var blob = new Blob([badge.body], { type: fileMIMEType });
    // saveAs(blob, 'c_tracking_badge-' + this.resource.id + '.pdf');
  }
}
