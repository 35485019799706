import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@JsonApiModelConfig({
  type: 'updates'
})




export class UpdatesModel extends JsonApiModel {
  @Attribute()
  // tslint:disable-next-line: no-unused-expression
  'update-id': string;
  @Attribute()
  author: {
    id: number;
    name: string;
    surname?: any;
    email: string;
    in_house: number;
    email_verified_at?: any;
    disable_login: number;
    created_at: string;
    updated_at: string;
    hasPhoto: boolean;
    profilePhoto?: any;
    in_house_user: number;
    is_admin: boolean;
    can_login: boolean;
  };
  @Attribute()
  'is-read': boolean;
  @Attribute()
  'message': string;

  @Attribute()
  'read-at': any;

  @Attribute()
  'task-id': number;

  @Attribute()
  'boat': string;

  @Attribute()
  'boat-id': string;

  @Attribute()
  'project': string;

  @Attribute()
  'project-id': number;

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}

const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    boats: UpdatesModel
  }
};

@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class UpdatesSevice extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }
  markAsRead<T extends JsonApiModel>(id: string, headers?: HttpHeaders) {
    const url = environment.api.base_url + environment.api.version + '/updates/' + id + '/mark-read';
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    return this.http.get(url, requestOptions);
  }
}
