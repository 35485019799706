import { Component, Output, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import * as _ from 'lodash';
// import * as moment from 'moment';

const defaultItem = {};

@Component({
  selector: 'n7-task-list-modal',
  templateUrl: './n7-task-list-modal.component.html',
  styleUrls: [
    './n7-task-list-modal.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class N7TaskListModalComponent implements OnChanges {
  public isLoading: boolean = true;
  public type: string = 'preview';
  public title: string = 'List';
  public items: any[] = [];
  public checkedItems: any[] = [];

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.type = this.navParams.get('type');
    this.title = this.navParams.get('title');
    this.items = this.navParams.get('items');
    this.checkedItems = [];
    this.isLoading = false;
  }

  @Output() itemClick = new EventEmitter();

  ngOnChanges() { }

  onTaskClick(item: any) {
    // console.log('item', item);
    this.toggleItemCheck(item.id)
  }

  toggleItemCheck(key: string) {
    if (this.checkedItems.length === _.pull(this.checkedItems, key).length) {
      this.checkedItems.push(key);
    }
  }

  async dismiss(validated: boolean = false) {
    let response = {
      checkedItems: []
    };
    if (validated) {
      response.checkedItems = this.checkedItems;
    }
    await this.modalController.dismiss(response);
  }
}
