import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromSections from "./sections.actions";
import { Section } from './sections.model';

export interface SectionsState {
  entities: Section[];
  isSending: boolean;
  error: string | any;
}

export const initialState: SectionsState = {
  entities: [],
  isSending: false,
  error: null
};

export function reducer(
  state = initialState,
  action: fromSections.SectionsActions
): SectionsState {

  switch (action.type) {
    case fromSections.ActionTypes.CleanAllSections: {
      return initialState;
    }

    case fromSections.ActionTypes.AddSection: {
      return {
        ...state,
        entities: [...state.entities, action.payload.data]
      };
    }

    case fromSections.ActionTypes.RemoveSection: {
      return {
        ...state,
        entities: state.entities.filter(item => item.id !== action.payload.data),
      };
    }

    default: {
      return state;
    }
  }
}
export const getSectionsState = createFeatureSelector<SectionsState>('sections');

export const selectAllSections = createSelector(getSectionsState, (state) => (state.entities));

export const selectSectionByID = (id: string) => createSelector(
  selectAllSections,
  (allSections) => allSections.find(Section => Section.id === id)
);

export const selectAllSectionsByProjectID = (id: string) => createSelector(
  selectAllSections,
  (allSections) => allSections.filter(Section => Section.project_id === id)
);

// export const getLoading = (state: SectionsState) => state.loading;

export const getError = (state: SectionsState) => state.error;
