import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-st-percentage-ring',
  templateUrl: './st-percentage-ring.component.html',
  styleUrls: ['./st-percentage-ring.component.scss'],
})
export class StPercentageRingComponent implements OnInit {

  itemPercentage: number;
  itemProgressValue: number;
  itemIsSmall: boolean
  itemDescription: string;

  @Input() set percentage(val: number) {
    this.itemPercentage = (val !== undefined && val !== null) ? val : 0;
  }
  @Input() set isSmall(val: boolean) {
    this.itemIsSmall = (val !== undefined && val !== null) ? val : false;
  }
  @Input() set description(val: string) {
    this.itemDescription = (val !== undefined && val !== null) ? val : '';
  }

  constructor() { }

  ngOnInit() {
    this.itemProgressValue = this.calculateProgressValue();
  }

  ngOnChanges() {
    this.itemProgressValue = this.calculateProgressValue();
  }

  calculateProgressValue() {
    if (!this.itemIsSmall)
      var dasharray = 538;
    else
      var dasharray = 157
    let value = dasharray - (dasharray * this.itemPercentage) / 100;

    // console.log(value)
    return value;
  }
}
