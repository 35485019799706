import { PophoverComponent } from '../pophover/pophover.component';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { UpdatesSevice, UpdatesModel } from 'src/app/core/services/notifications.service';
import { UserService } from './../../core/services/user.service';
import { BehaviorSubject, Observable, Subject, fromEvent, interval } from 'rxjs';
import { audit, auditTime, take, takeUntil, throttle } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})

export class AppHeaderComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  name: string;
  accessToken: string;
  notificatonNumbers = null;
  count$: Observable<number>;
  route = 'home';

  private backAlert = null;

  private timeInterval = 10000;

  public isDesktop = false;
  public isTablet = false;
  public isMobile = false;

  constructor(
    private store: Store<{ count: number }>,
    private router: Router,
    public platform: Platform,
    public alertController: AlertController,
    public popoverController: PopoverController,
    private updatesService: UpdatesSevice,
    private userService: UserService) {
    this.count$ = store.pipe(select('count'));
    this.notificatonNumbers = null;
  }

  @Input() set section(val: string) {
    this.name = (val !== undefined && val !== null) ? val : '';
  }

  @Input() buttons = {
    left: {
      icon: 'home',
      route: 'home',
      label: null,
    },
    right: {
      notification: true,
      profile: true,
      selectPoints: false,
      options: false
    }
  };

  @Input() set backConfirm(val: any) {
    this.backAlert = (val !== undefined && val !== null) ? val : null;
  }

  async presentNotifications(ev: any) {
    const popover = await this.popoverController.create({
      component: PophoverComponent,
      componentProps: { option: 'notification', accessToken: this.accessToken },
      cssClass: 'popover-notifications',
      event: ev,
      translucent: false
    });

    this.markAllAsRead();

    return await popover.present();
  }

  async presentProfile(ev: any) {
    const popover = await this.popoverController.create({
      component: PophoverComponent,
      componentProps: { option: 'profile', accessToken: this.accessToken },
      cssClass: 'popover-profile',
      event: ev,
      translucent: false
    });
    return await popover.present();
  }

  async presentBackConfirm(callback) {
    const alert = await this.alertController.create({
      header: this.backAlert.title,
      message: this.backAlert.message,
      buttons: [
        {
          text: 'Dismiss',
          handler: () => {
            alert.dismiss();
          }
        },
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
            callback();

          }
        }
      ]
    });
    await alert.present();
  }

  back(route: string) {
    if (this.backAlert) {
      this.presentBackConfirm(() => { this.router.navigateByUrl(route); });
    }
    else {
      this.router.navigateByUrl(route);
    }
  }

  async ngOnInit() {
    this.isDesktop = false;
    this.isTablet = false;
    this.isMobile = false;

    this.platform.ready().then(() => {
      if (this.platform.is('desktop')) {
        this.isDesktop = true;
      }
      if (this.platform.is('tablet')) {
        this.isTablet = true;
      }
      if (this.platform.is('mobile')) {
        this.isMobile = true;
      }
    });

    await this.authorize();
    this.notificatonNumbers = 0;
    this.getNotification();

    const clicks = fromEvent(document, 'click');
    const result = clicks.pipe(
      takeUntil(this.unsubscribe$),
      throttle(ev => interval(this.timeInterval))
    );
    result.subscribe(() => { this.getNotification(); });


    // this.userService.getToken().pipe(
    //   take(1)
    // // tslint:disable-next-line: deprecation
    // ).subscribe(accessToken => {
    //   console.log('get token', accessToken, this.accessToken);
    //   if (!accessToken) {
    //     this.router.navigateByUrl('auth');
    //   } else {
    //     // tslint:disable-next-line: variable-name
    //     const _s = this;
    //     this.accessToken = accessToken;

    //     this.notificatonNumbers = 0;
    //     this.getNotification();

    //     const clicks = fromEvent(document, 'click');
    //     const result = clicks.pipe(
    //       takeUntil(this.unsubscribe$),
    //       throttle(ev => interval(this.timeInterval))
    //     );
    //     result.subscribe(() => { this.getNotification(); });
    //   }
    // });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async authorize() {
    return new Promise((resolve, reject) => {
      this.userService.getToken().pipe(
        take(1)
      ).subscribe(accessToken => {
        if (!accessToken) {
          this.router.navigateByUrl('auth');
        } else {
          this.accessToken = accessToken;
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken });
          this.updatesService.headers = headers;
        }
        // console.log('authorize');
        resolve(true);
      }, (error) => {
        reject(error);
      });
    });
  }

  getNotification() {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken });
    this.updatesService.headers = headers;
    return;
    this.updatesService.getCollection(UpdatesModel).pipe(
      take(1)
    ).subscribe((data: any) => {
      // this.notificatonNumbers = Object.keys(data).length;
      this.notificatonNumbers = 0;
      data.forEach(element => {
        if (element['is-read'] !== true) {
          this.notificatonNumbers++;
        }
      });
    });
  }

  markAllAsRead() {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accessToken });
    this.updatesService.headers = headers;
    this.updatesService.getCollection(UpdatesModel).pipe(
      take(1)
    ).subscribe((data: any) => {
      data.forEach(element => {
        if (element['is-read'] !== true) {
          this.updatesService.markAsRead(element['update-id']).subscribe(data => { });
        }
      });
      this.notificatonNumbers = 0;
    });
  }
}

