import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface componentInteraction {
  remove(index: number);
  onItemChange(index: number, itemData: N7SectionUnderApplicationItem);
}

export interface N7SectionUnderApplicationItem {
  id: string,
  name: string,
  section: any,
  description: string,
  images: any[],
}

@Component({
  selector: 'n7-section-under-application-item',
  templateUrl: './n7-section-under-application-item.component.html',
  styleUrls: ['./n7-section-under-application-item.component.scss'],
})
export class N7SectionUnderApplicationItemComponent implements OnChanges {
  // @Input() item: N7SectionUnderApplicationItem;

  @Input() set item(val: N7SectionUnderApplicationItem) {
    this.itemData = (val !== undefined && val !== null) ? val : {
      id: '',
      name: '',
      section: {},
      description: '',
      images: [],
    };
    // console.log('itemData', this.itemData);
  }

  @Input() set listOptions(val: []) {
    this.sections = (val !== undefined && val !== null) ? val : [];
    console.log('sections', this.sections);
  }

  public itemData: N7SectionUnderApplicationItem;
  public index: number;
  public name: string;
  public section: any;
  public selfRef: N7SectionUnderApplicationItemComponent;

  public sections = [];

  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  constructor() {
    // this.sections = [];
    // this.sections.push({
    //   key: '0',
    //   label: 'label'
    // });
    // this.sections.push({
    //   key: '1',
    //   label: 'label1'
    // });
  }

  ngOnChanges() {}

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  itemChange(index) {
    if (this.compInteraction) {
      this.compInteraction.onItemChange(index, this.itemData);
    }
  }

  onSectionChange(event) {
    // console.log(event);
    this.itemData.section = event;
    this.itemChange(this.index);
  }
}
