import { Action } from "@ngrx/store";
import { Boat} from "../../state/boat/boat.model";

export enum ActionTypes {
  Init = "[Boat Service] Init",
  SetFilters = "[Boat Service] Set filters",
  SetTasks = "[Boat Service] Set tasks",

  // LoginUserBegin = "[User Service] Login boat begin",
  // LoginUserSuccess = "[User Service] Login boat Success",
  // LoginUserFailure = "[User Service] Login boat Failure",

  // RefreshTokenBegin = "[User Service] Refresh token begin",

  // LogoutUserBegin = "[User Service] Logout boat begin",
  // LogoutUserSuccess = "[User Service] Logout boat Success",
  // LogoutUserFailure = "[User Service] Logout boat Failure",

  // GetUserBegin = "[User Service] Get boat begin",

  // LoadUserBegin = "[User Service] Load boat begin",
  // LoadUserSuccess = "[User Service] Load boat success",
  // LoadUserFailure = "[User Service] Load boat failure",

  // ShowedHint = "[User Service] Showed hint"
}

export class Init implements Action {
  readonly type = ActionTypes.Init;
  constructor(public payload: {
    boatId: number,
    projectId: number,
    filters: any,
    tasks: any[],
  }) {}
}

export class SetFilters implements Action {
  readonly type = ActionTypes.SetFilters;
  constructor(public payload: {
    filters: any,
  }) {}
}

export class SetTasks implements Action {
  readonly type = ActionTypes.SetTasks;
  constructor(public payload: {
    tasks: any[],
  }) {}
}

// export class LoginUserBegin implements Action {
//   readonly type = ActionTypes.LoginUserBegin;

//   constructor(public payload: { data: Credentials }) {}
// }

// export class LoginUserSuccess implements Action {
//   readonly type = ActionTypes.LoginUserSuccess;

//   constructor(public payload: { data: any }) {}
// }

// export class LoginUserFailure implements Action {
//   readonly type = ActionTypes.LoginUserFailure;

//   constructor(public payload: { error: any }) {}
// }

// export class RefreshTokenBegin implements Action {
//   readonly type = ActionTypes.RefreshTokenBegin;
// }

// export class LogoutUserBegin implements Action {
//   readonly type = ActionTypes.LogoutUserBegin;

//   constructor(public payload: { access_token: string }) {}
// }

// export class LogoutUserSuccess implements Action {
//   readonly type = ActionTypes.LogoutUserSuccess;

//   constructor(public payload: { data: any }) {}
// }

// export class LogoutUserFailure implements Action {
//   readonly type = ActionTypes.LogoutUserFailure;

//   constructor(public payload: { error: any }) {}
// }

// export class GetUserBegin implements Action {
//   readonly type = ActionTypes.GetUserBegin;
// }



// export class LoadUserBegin implements Action {
//   readonly type = ActionTypes.LoadUserBegin;
// }

// export class LoadUserSuccess implements Action {
//   readonly type = ActionTypes.LoadUserSuccess;

//   constructor(public payload: { data: any }) {}
// }

// export class LoadUserFailure implements Action {
//   readonly type = ActionTypes.LoadUserFailure;

//   constructor(public payload: { error: any }) {}
// }


// export class ShowedHint implements Action {
//   readonly type = ActionTypes.ShowedHint;

//   constructor(public payload: { hint: string }) {}
// }

export type ActionsUnion =
  // LoginUserBegin
  // | LoginUserSuccess
  // | LoginUserFailure
  // | RefreshTokenBegin
  // | LogoutUserBegin
  // | LogoutUserSuccess
  // | LogoutUserFailure
  // | GetUserBegin
  // | LoadUserBegin
  // | LoadUserSuccess
  // | LoadUserFailure
  // | ShowedHint;
  Init
  | SetFilters
  | SetTasks;