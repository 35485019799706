import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-file-upload',
  templateUrl: './n7-file-upload.component.html',
  styleUrls: ['./n7-file-upload.component.scss'],
})
export class N7FileUploadComponent implements OnChanges {
  public index: number;
  public selfRef: N7FileUploadComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemHint: string;
  public itemHasError: boolean;
  public itemError: string;
  public itemName: string;
  public itemFile: string;
  public itemFileAccept: string;
  public itemValue: string;
  public itemUnit: string;
  public itemType: string;
  public itemTitle: string;
  public itemAsBase64: boolean;

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hint(val: string) {
    this.itemHint = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set name(val: string) {
    this.itemName = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set fileAccept(val: string) {
    this.itemFileAccept = (val !== undefined && val !== null) ? val : '*';
  }

  @Input() set value(val: string) {
    this.itemValue = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set unit(val: string) {
    this.itemUnit = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set type(val: string) {
    this.itemType = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set title(val: string) {
    this.itemTitle = (val !== undefined && val !== null) ? val : 'Upload file';
  }

  @Input() set asBase64(val: boolean) {
    this.itemAsBase64 = (val !== undefined && val !== null) ? val : true;
  }

  @Output() valueChange = new EventEmitter()

  constructor() { }

  ngOnChanges() { }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  onItemClear(event) {
    this.itemValue = '';
    this.itemFile = '';
    this.itemName = '';
    this.itemHasError = false;
    this.itemError = '';
    this.valueChange.emit(this.itemValue);
  }

  onItemChange(event) {
    this.valueChange.emit(this.itemValue);
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      this.itemValue = encoded;
      this.valueChange.emit(this.itemValue);
    },
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
  }

  onLoadFile(event) {
    if (event.target.files[0] && event.target.files[0].name) {
      this.itemName = event.target.files[0].name;
      if (this.itemAsBase64) {
        this.getBase64(event.target.files[0]);
      }
      else {
        this.itemValue = event.target.files[0];
        this.valueChange.emit(this.itemValue);
      }
    }
  }
}
