import { Component, OnChanges, Input, Output, EventEmitter, ComponentRef, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { N7SimpleItemComponent } from '../n7-simple-item/n7-simple-item.component'
import { N7SectionUnderApplicationItemComponent, N7SectionUnderApplicationItem } from '../n7-section-under-application-item/n7-section-under-application-item.component'
import { N7ItemEnvLogComponent } from '../n7-item-env-log/n7-item-env-log.component';

import * as uuid from 'uuid';

@Component({
  selector: 'n7-multiple-items',
  templateUrl: './n7-multiple-items.component.html',
  styleUrls: ['./n7-multiple-items.component.scss'],
})
export class N7MultipleItemsComponent implements OnChanges {
  @ViewChild('viewContainerRef', { read: ViewContainerRef }) VCR: ViewContainerRef;
  public internalItemType = '';
  public internalItems: any[] = [];
  public internalItemsLength = 0;
  public internalItemsOptions: any = {};
  public internalShowAddItem = false;
  public itemLabel: string;

  public index: number = 0;
  public componentsReferences = [];

  @Input() set itemType(val: string) {
    this.internalItemType = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set items(val: any[]) {
    this.internalItems = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set itemsLength(val: number) {
    this.internalItemsLength = (val !== undefined && val !== null) ? val : 0;
  }

  @Input() set itemsOptions(val: any) {
    this.internalItemsOptions = (val !== undefined && val !== null) ? val : {};
  }

  @Input() set showAddItem(val: boolean) {
    this.internalShowAddItem = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Output() itemsChange = new EventEmitter()

  constructor(private CFR: ComponentFactoryResolver) {}

  ngOnChanges() {
    this.removeAll(false);
    this.internalItems.forEach(element => {
      this.createComponent(element, false);
    });
  }

  createComponent(itemData: any, updateInternalItems: boolean = true) {
    switch (this.internalItemType) {
      case 'N7ItemEnvLogComponent':
        this.createN7ItemEnvLogComponent(itemData, updateInternalItems);
        break;

      case 'N7SectionUnderApplicationItemComponent':
        this.createN7SectionUnderApplicationItemComponent(itemData, updateInternalItems);
        break;

      default:
        console.log('default');
    }
  }

  createN7ItemEnvLogComponent(itemData: any, updateInternalItems: boolean = true) {
    console.log('itemData', itemData);
    let componentFactory = this.CFR.resolveComponentFactory(N7ItemEnvLogComponent);
    let componentRef: ComponentRef<N7ItemEnvLogComponent> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;

    currentComponent.selfRef = currentComponent;
    currentComponent.index = ++this.index;
    // providing parent Component reference to get access to parent class methods
    currentComponent.compInteraction = this;
    // add reference for newly created component
    this.componentsReferences.push(componentRef);
    currentComponent.item = itemData;
    // console.log('internalItemsOptions', this.internalItemsOptions);
    if (this.internalItemsOptions) {
      Object.keys(this.internalItemsOptions).forEach(itemsOption => {
        // console.log('itemsOption', itemsOption);
        // console.log(this.internalItemsOptions[itemsOption]);
        currentComponent[itemsOption] = this.internalItemsOptions[itemsOption];
      });
    }

    if (updateInternalItems) {
      console.log('adding internal');
      const tempId = uuid.v4();
      this.internalItems.push({
        index: currentComponent.index,
        id: (itemData && itemData.id) ? itemData.id : tempId,
        file: (itemData && itemData.file) ? itemData.file : '',
        location: (itemData && itemData.location) ? itemData.location : '',
      });

      this.itemsChange.emit(this.internalItems);
    }
    else {
      console.log('updating internal', this.internalItems);
    }
  }

  createN7SectionUnderApplicationItemComponent(itemData: any, updateInternalItems: boolean = true) {
    let componentFactory = this.CFR.resolveComponentFactory(N7SectionUnderApplicationItemComponent);
    let componentRef: ComponentRef<N7SectionUnderApplicationItemComponent> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;

    currentComponent.selfRef = currentComponent;
    currentComponent.index = ++this.index;
    // providing parent Component reference to get access to parent class methods
    currentComponent.compInteraction = this;
    // add reference for newly created component
    this.componentsReferences.push(componentRef);
    currentComponent.item = itemData;
    console.log(this.internalItemsOptions);
    if (this.internalItemsOptions && this.internalItemsOptions.listOptions) {
      console.log(this.internalItemsOptions.listOptions);
      currentComponent.listOptions = this.internalItemsOptions.listOptions;
    }

    if (updateInternalItems) {
      console.log('adding internal');
      const tempId = uuid.v4();
      this.internalItems.push({
        index: currentComponent.index,
        id: (itemData && itemData.id) ? itemData.id : tempId,
        name: (itemData && itemData.name) ? itemData.name : '',
        section: (itemData && itemData.section) ? itemData.section : {},
        description: (itemData && itemData.description) ? itemData.description : '',
        images: (itemData && itemData.images) ? itemData.images : []
      });

      this.itemsChange.emit(this.internalItems);
    }
    else {
      console.log('updating internal', this.internalItems);
    }
  }

  remove(index: number, updateInternalItems: boolean = true) {
    if (this.VCR.length < 1) {
      return;
    }

    let componentRef = this.componentsReferences.filter(x => x.instance.index == index)[0];
    let vcrIndex: number = this.VCR.indexOf(componentRef)

    // removing component from container
    this.VCR.remove(vcrIndex);

    this.componentsReferences = this.componentsReferences.filter(x => x.instance.index !== index);

    if (updateInternalItems) {
      this.internalItems = this.internalItems.filter(x => x.index !== index);
    }
  }

  removeAll(updateInternalItems: boolean = true) {
    this.componentsReferences.forEach(element => {
      // console.log('element', element);
      this.remove(element.instance.index, updateInternalItems);
    });
    this.index = 0;
  }


  onItemChange(index: number, itemData: any, updateInternalItems: boolean = true) {
    // console.log('onItemChange()', index);
    // console.log('onItemChange()2', itemData);
    if (this.VCR.length < 1) {
      return;
    }

    if (updateInternalItems) {
      this.internalItems.forEach((element, internalIndex) => {
        if (element.id === itemData.id) {
          this.internalItems[internalIndex] = itemData;
          this.internalItems[internalIndex].index = index;
        }
      });
    }
  }
}
