import { Action } from "@ngrx/store";
import { Project } from "./projects.model";

export enum ActionTypes {
  CleanAllProjects = "[PROJECTS State] Clean All",

  AddProject = "[PROJECTS State] Add Project",
  RemoveProject = "[PROJECTS State] Remove Project",
}

export class CleanAllProjects implements Action {
  readonly type = ActionTypes.CleanAllProjects;

  constructor() { }
}

export class AddProject implements Action {
  readonly type = ActionTypes.AddProject;

  constructor(public payload: { data: Project }) { }
}

export class RemoveProject implements Action {
  readonly type = ActionTypes.RemoveProject;

  constructor(public payload: { data: string | number }) { }
}

export type ProjectsActions =
  CleanAllProjects
  | AddProject
  | RemoveProject;
