import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-text-input',
  templateUrl: './n7-text-input.component.html',
  styleUrls: ['./n7-text-input.component.scss'],
})
export class N7TextInputComponent implements OnChanges {
  public index: number;
  public name: string;
  public selfRef: N7TextInputComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemHint: string;
  public itemHintType: string;
  public itemHasError: boolean;
  public itemError: string;
  public itemValue: string;
  public itemUnit: string;
  public itemSuffixClass: string;
  public itemType: string;
  public itemPlaceholder: string;
  public itemPrefix: string;
  public itemHasAction: boolean;
  public itemActionDisabled: boolean;
  public itemActionLabel: string;
  public itemRegexp: string;
  public itemDisabled: boolean;

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hint(val: string) {
    this.itemHint = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hintType(val: string) {
    this.itemHintType = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set value(val: string) {
    this.itemValue = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set unit(val: string) {
    this.itemUnit = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set suffixClass(val: string) {
    this.itemSuffixClass = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set type(val: string) {
    this.itemType = (val !== undefined && val !== null) ? val : 'text';
  }

  @Input() set placeholder(val: string) {
    this.itemPlaceholder = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set prefix(val: string) {
    this.itemPrefix = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasAction(val: boolean) {
    this.itemHasAction = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set actionDisabled(val: boolean) {
    this.itemActionDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set actionLabel(val: string) {
    this.itemActionLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set regexp(val: string) {
    this.itemRegexp = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set disabled(val: boolean) {
    this.itemDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Output() valueChange = new EventEmitter()

  constructor() { }

  ngOnChanges() {
    this.checkErrors()
  }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }
  onItemClear(event) {
    this.itemValue = '';
    this.itemHasError = false;
    // this.itemError = '';
    this.valueChange.emit(this.itemValue);
  }

  onItemChange(event) {
    this.checkErrors();
    if (('number' === this.itemType) && ('string' == typeof this.itemValue)) {
      this.itemValue = this.itemValue.replace(',', '.');
    }
    this.valueChange.emit(this.itemValue);
  }

  onKeyUp(event) {
    this.onItemChange(event);
  }

  checkErrors() {
    if (this.itemValue && this.itemRegexp) {
      const regexp = new RegExp(this.itemRegexp);
      const isValid = regexp.test(this.itemValue);
      if (isValid) {
        this.itemHasError = false;
      }
      else {
        this.itemHasError = true;
      }
    }
    else {
      this.itemHasError = false;
    }
  }
}
