import { UpdatesSevice, UpdatesModel } from './../../core/services/notifications.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/state/user/user.model';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-pophover',
  templateUrl: './pophover.component.html',
  styleUrls: ['./pophover.component.scss'],
})
export class PophoverComponent implements OnInit {
  public option = null;
  public user: User;
  public notifications: any;
  public exports: any;
  public accessToken = '';
  public boatId = '';
  public projectId = '';

  constructor(
    public popoverController: PopoverController,
    private navParams: NavParams,
    private userService: UserService,
    private updatesService: UpdatesSevice,
    private router: Router,

    ) {
      this.option = this.navParams.get('option');
      this.accessToken = this.navParams.get('accessToken');
      this.boatId = this.navParams.get('boatId');
      this.projectId = this.navParams.get('projectId');
    }

    async ngOnInit() {
      if (this.option === 'notification') {
        const headers = new HttpHeaders({Authorization: 'Bearer ' + this.accessToken});
        this.updatesService.headers = headers;

        await this.sleep(100);
        // setTimeout(() => {
          // this.updatesService.getCollection(UpdatesModel).subscribe(
          //   (data) => {
          //     this.notifications = _.orderBy(data, ['created-at'], ['desc']);
          //   },
          //   (error) => {
          //     this.notifications = [];
          //   }
          // );
        // }, 100);
      }

      if (this.option === 'profile') {
        // mostro il profilo dell'utente
        this.userService.getToken()
        .subscribe(accessToken => {
          this.accessToken = accessToken;
          //this.router.navigateByUrl('auth');
        });
        this.userService.getUser()
        .subscribe(userData => {
          this.user = userData;
          // console.log(this.user);
        });
      }

      if (this.option === 'export') {
        this.exports = [
          {
            id: 'coating-diary',
            type: 'export',
            title: 'Coating diary',
            description: 'This report is specially useful for you',
            color: '#F8E71C',
            disabled: true
          }, {
            id: 'list-of-points',
            type: 'export',
            title: 'List of points',
            description: 'This report is specially useful for you',
            color: '#50E3C2',
            disabled: false
          }, {
            id: 'weekly-report',
            type: 'export',
            title: 'Weekly report',
            description: 'This report is specially useful for you',
            color: '#F5A623',
            disabled: true
          }, {
            id: 'environmentals',
            type: 'export',
            title: 'Environmental report',
            description: 'This report is specially useful for you',
            color: '#9013FE',
            disabled: false
          }
        ];
      }
    }

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    settings() {
      this.popoverController.dismiss();
      this.router.navigateByUrl('settings');
    }

    logout() {
      this.popoverController.dismiss();
      this.userService.logout();
      this.router.navigateByUrl('auth');
    }

    onNotificationClick(notificationId) {
      this.popoverController.dismiss();
    }

    onExportClick(item) {
      this.popoverController.dismiss();
      // console.log('export', item);
      const route = 'boat/' + this.boatId + '/project/' + this.projectId + '/' + item.type + '/' + item.id;
      this.router.navigate([route]);
    }
  }
