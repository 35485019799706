import { Component, Input, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
// import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';

@Component({
  selector: 'app-st-chart-pie',
  templateUrl: './st-chart-pie.component.html',
  styleUrls: ['./st-chart-pie.component.scss'],
})
export class StChartPieComponent implements OnInit {

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    // tooltips: {
    //   // Disable the on-canvas tooltip
    //   enabled: false,
    //   //add "€" mark at end of tooltip info
    //   callbacks: {
    //     label: function (tooltipItem, pieChartData) {
    //       var label = pieChartData.labels[tooltipItem.index];
    //       var datasetLabel = pieChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
    //       return label + ': ' + datasetLabel + ' €';
    //     }
    //   },
    //   //show tooltip without cutting off
    //   custom: function (tooltipModel) {
    //     // Tooltip Element
    //     var tooltipEl = document.getElementById('chartjs-tooltip');

    //     // Create element on first render
    //     if (!tooltipEl) {
    //       tooltipEl = document.createElement('div');
    //       tooltipEl.id = 'chartjs-tooltip';
    //       tooltipEl.innerHTML = '<table></table>';
    //       tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    //       tooltipEl.style.color = "#ffffff";
    //       tooltipEl.style.fontWeight = "bold";
    //       document.body.appendChild(tooltipEl);
    //     }

    //     // Hide if no tooltip
    //     if (tooltipModel.opacity === 0) {
    //       tooltipEl.style.opacity = '0';
    //       return;
    //     }

    //     // Set caret Position
    //     tooltipEl.classList.remove('above', 'below', 'no-transform');
    //     if (tooltipModel.yAlign) {
    //       tooltipEl.classList.add(tooltipModel.yAlign);
    //     } else {
    //       tooltipEl.classList.add('no-transform');
    //     }

    //     function getBody(bodyItem) {
    //       return bodyItem.lines;
    //     }

    //     // Set Text
    //     if (tooltipModel.body) {
    //       var titleLines = tooltipModel.title || [];
    //       var bodyLines = tooltipModel.body.map(getBody);

    //       var innerHtml = '<thead>';

    //       titleLines.forEach(function (title) {
    //         innerHtml += '<tr><th>' + title + '</th></tr>';
    //       });
    //       innerHtml += '</thead><tbody>';

    //       bodyLines.forEach(function (body, i) {
    //         var colors = tooltipModel.labelColors[i];
    //         var style = 'background:' + colors.backgroundColor;
    //         style += '; border-color:' + colors.borderColor;
    //         style += '; border-width: 2px';
    //         var span = '<span style="' + style + '"></span>';
    //         innerHtml += '<tr><td>' + span + body + '</td></tr>';
    //       });
    //       innerHtml += '</tbody>';

    //       var tableRoot = tooltipEl.querySelector('table');
    //       tableRoot.innerHTML = innerHtml;
    //     }

    //     // `this` will be the overall tooltip
    //     var position = this._chart.canvas.getBoundingClientRect();

    //     // Display, position, and set styles for font
    //     tooltipEl.style.opacity = '1';
    //     tooltipEl.style.position = 'absolute';
    //     tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    //     tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    //     tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    //     tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    //     tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    //     tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    //     tooltipEl.style.pointerEvents = 'none';
    //   }
    // }
  };
  // public pieChartLabels: Label[] = [];
  // public pieChartData: SingleDataSet = [];
  public pieChartLabels: any[] = [];
  public pieChartData: any = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartPlugins = [];
  public pieChartColors: any[] = [];

  chartPie: string;
  itemPieData;
  itemSizeLg;
  itemIsEmpty;
  itemInfoCompany;
  itemInfoCost;

  @Input() set pieData(val: any) {
    this.itemPieData = val !== undefined && val !== null ? val : [];
  }
  @Input() set sizeLg(val: boolean) {
    this.itemSizeLg = val !== undefined && val !== null ? val : false;
  }
  @Input() set isEmpty(val: boolean) {
    this.itemIsEmpty = val !== undefined && val !== null ? val : false;
  }
  @Input() set infoCompany(val: string) {
    this.itemInfoCompany = val !== undefined && val !== null ? val : '';
  }
  @Input() set infoCost(val: string) {
    this.itemInfoCost = val !== undefined && val !== null ? val : '';
  }

  constructor() {
    // monkeyPatchChartJsTooltip();
    // monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    this.calculatePie(this.itemPieData);
  }

  ngOnChanges() {
    this.calculatePie(this.itemPieData);
  }

  calculatePie(data) {
    this.pieChartLabels = data.map(el => el.name);
    this.pieChartData = data.map(el => el.amount);
    var dataColors = data.map(el => el.color);
    this.pieChartColors = [{ backgroundColor: dataColors }];
    // if(!this.itemSizeLg) 
    //   this.pieChartOptions.tooltips.enabled = false;
  }

}
