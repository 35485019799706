import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { ModalController } from "@ionic/angular";
import * as _ from "lodash";

export interface componentInteraction {
  remove(index: number);
}

export interface N7CheckboxListOptions {
  key: string;
  label: string;
  icon?: string;
  iconUlr?: string;
  image?: string;
}

@Component({
  selector: 'app-st-filter-checkbox-list',
  templateUrl: './st-filter-checkbox-list.component.html',
  styleUrls: ['./st-filter-checkbox-list.component.scss'],
  animations: [
    trigger("dropdownAnimation", [
      transition(":enter", [
        style({ opacity: 0, "margin-top": "-5px" }),
        animate("200ms", style({ opacity: 1, "margin-top": "0" })),
      ]),
      transition(":leave", [
        animate("200ms", style({ opacity: 0, "margin-top": "-5px" })),
      ]),
    ]),
  ],
})
export class StFilterCheckboxListComponent implements OnChanges {

  public index: number;
  public name: string;
  public selfRef: StFilterCheckboxListComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemIsOpen: boolean;
  public itemDisabled: boolean;
  public itemHasClearOption: boolean;
  public itemLabelClearOption: string = "No selection";
  public itemHasBackdrop: boolean;
  public itemHasError: boolean;
  public itemError: string;
  public itemValue: string[] = [];
  public itemTitle: string;
  public itemOptions: N7CheckboxListOptions[];
  public itemExclusiveOptions: string[] = [];
  public itemHint: string;

  public itemCheckedNumber: number = 0;
  delayTimer: any = null;

  @Input() set label(val: string) {
    this.itemLabel = val !== undefined && val !== null ? val : "";
  }

  @Input() set isOpen(val: boolean) {
    this.itemIsOpen = val !== undefined && val !== null ? val : false;
  }

  @Input() set hasClearOption(val: boolean) {
    this.itemHasClearOption =
      val !== undefined && val !== null ? val : false;
  }

  @Input() set labelClearOption(val: string) {
    this.itemLabelClearOption =
      val !== undefined && val !== null ? val : "No selection";
  }

  @Input() set hasBackdrop(val: boolean) {
    this.itemHasBackdrop = val !== undefined && val !== null ? val : false;
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = val !== undefined && val !== null ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = val !== undefined && val !== null ? val : "";
  }

  @Input() set value(val: string[]) {
    this.itemValue = val !== undefined && val !== null ? val : [];
  }

  @Input() set options(val: []) {
    this.itemOptions = val !== undefined && val !== null ? val : [];
  }

  @Input() set exclusiveOptions(val: []) {
    this.itemExclusiveOptions =
      val !== undefined && val !== null ? val : [];
  }

  @Input() set title(val: string) {
    this.itemTitle = val !== undefined && val !== null ? val : "Choose";
  }

  @Input() set disabled(val: boolean) {
    this.itemDisabled = val !== undefined && val !== null ? val : false;
  }

  @Input() set checkedNumber(val: number) {
    this.itemCheckedNumber = val !== undefined && val !== null ? val : 0;
  }

  @Output() valueChange = new EventEmitter();

  constructor(public modalController: ModalController) { }

  ngOnChanges() { }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index);
    }
  }

  getLabelFromKey(key: string) {
    let label = "";
    const index = _.findIndex(this.itemOptions, (o) => {
      return o.key === key;
    });
    if (index >= 0) {
      label = this.itemOptions[index].label;
    }
    return label ? label : this.itemTitle;
  }

  onItemToggle(event) {
    this.itemIsOpen = !this.itemIsOpen;
    // this.valueChange.emit(this.itemValue);
  }

  isExclusiveOption(key: string) {
    const exclusiveOptionIndex = _.findIndex(
      this.itemExclusiveOptions,
      (o) => {
        return o === key;
      }
    );
    if (exclusiveOptionIndex >= 0) {
      return true;
    }
    return false;
  }

  onItemSelected(key: string) {
    const index = _.findIndex(this.itemOptions, (o) => {
      return o.key === key;
    });
    if (index >= 0) {
      if (this.isExclusiveOption(key)) {
        if (
          this.itemValue.length === _.pull(this.itemValue, key).length
        ) {
          this.itemValue = [];
          this.itemValue.push(key);
        }
      } else {
        this.itemExclusiveOptions.forEach((o) => {
          _.pull(this.itemValue, o);
        });
        if (
          this.itemValue.length === _.pull(this.itemValue, key).length
        ) {
          this.itemValue.push(key);
        }
      }

      // this.itemIsOpen = false;
      // this.itemValue = key;
      // this.valueChange.emit(this.itemValue);
    } else {
      this.itemIsOpen = false;
    }
    this.showCheckedNumber();
  }

  onItemClear() {
    this.itemIsOpen = false;
    this.itemValue = [];
    this.itemCheckedNumber = 0;
    this.valueChange.emit(this.itemValue);
  }

  onItemChange(event) {
    this.valueChange.emit(this.itemValue);
  }

  onKeyUp(event) {
    this.valueChange.emit(this.itemValue);
  }

  showCheckedNumber() {
    if (this.delayTimer) clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.itemIsOpen = false;
      if (this.itemValue.length > 0) {
        this.itemCheckedNumber = this.itemValue.length;
        this.valueChange.emit(this.itemValue);
      } else {
        this.itemCheckedNumber = 0;
        this.valueChange.emit(this.itemValue);
      }
    }, 1000);
  }

}
