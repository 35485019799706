import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-simple-item',
  templateUrl: './n7-simple-item.component.html',
  styleUrls: ['./n7-simple-item.component.scss'],
})
export class N7SimpleItemComponent implements OnChanges {
  public index: number;
  public name: string;
  public selfRef: N7SimpleItemComponent;

  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  constructor() {}

  ngOnChanges() {}

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }
}
