import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import * as uuid from 'uuid';
// import * as moment from 'moment';
import { formatDate } from 'date-fns';

@Injectable()
export class Utilities {
  static getParameterByName(name: string, url: string) {
    if (!url) { url = window.location.href; }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  static getIcon(state, is_open, size) {

  }


  /** ritorna il path dell'icona active size standard */
  static getIconPath(state, isOpen) {
    // console.log(state, isOpen);
    const Icons = {
      draft: '/assets/pins/Draft/Active.svg',
      submitted: '/assets/pins/Sent/Active.svg',
      accepted: '/assets/pins/Accepted/Active.svg',
      'closed-completed': '/assets/pins/Closed/Completed/Active.svg',
      'closed-denied': '/assets/pins/Closed/Declined/Active.svg',
      completed: '/assets/pins/Completed/Active.svg',
      denied: '/assets/pins/Declined/Active.svg',
      'in progress': '/assets/pins/Progress/Active.svg',
      open: '/assets/pins/Remark/Open/Active.svg',
      'closed-open': '/assets/pins/Remark/NoActions/Closed/Active.svg',
      no_actions: '/assets/pins/Remark/NoActions/Active.svg',
      'closed-no_actions': '/assets/pins/Remark/NoActions/Closed/Active.svg',
      local_actions: '/assets/pins/Remark/Local/Active.svg',
      'closed-local_actions': '/assets/pins/Remark/Local/Closed/Active.svg',
      total_actions: '/assets/pins/Remark/Total/Active.svg',
      'closed-total_actions': '/assets/pins/Remark/Total/Closed/Active.svg',
      monitored: '/assets/pins/Monitored/Active.svg',
      remarked: '/assets/pins/Remark/Active.svg',
    };
    if (!isOpen && state === 'completed') {
      return Icons['closed-completed'];
    }
    if (!isOpen && state === 'denied') {
      return Icons['closed-denied'];
    }
    if (!isOpen && state === 'open') {
      return Icons['closed-open'];
    }
    if (!isOpen && state === 'no_actions') {
      return Icons['closed-no_actions'];
    }
    if (!isOpen && state === 'local_actions') {
      return Icons['closed-local_actions'];
    }
    if (!isOpen && state === 'total_actions') {
      return Icons['closed-total_actions'];
    }
    // console.log(Icons[state]);
    return Icons[state];
  }

  static getCirclePath(state, isOpen) {
    // console.log(state, isOpen);
    const Icons = {
      draft: '/assets/pins/Draft/Circle.svg',
      submitted: '/assets/pins/Sent/Circle.svg',
      accepted: '/assets/pins/Accepted/Circle.svg',
      'closed-completed': '/assets/pins/Closed/Completed/Circle.svg',
      'closed-denied': '/assets/pins/Closed/Declined/Circle.svg',
      completed: '/assets/pins/Completed/Circle.svg',
      denied: '/assets/pins/Declined/Circle.svg',
      'in progress': '/assets/pins/Progress/Circle.svg',
      open: '/assets/pins/Remark/Open/Circle.svg',
      'closed-open': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      no_actions: '/assets/pins/Remark/NoActions/Circle.svg',
      'closed-no_actions': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      local_actions: '/assets/pins/Remark/Local/Circle.svg',
      'closed-local_actions': '/assets/pins/Remark/Local/Closed/Circle.svg',
      total_actions: '/assets/pins/Remark/Total/Circle.svg',
      'closed-total_actions': '/assets/pins/Remark/Total/Closed/Circle.svg',
      monitored: '/assets/pins/Monitored/Circle.svg',
      remarked: '/assets/pins/Remark/Circle.svg',
    };
    if (!isOpen && state === 'completed') {
      return Icons['closed-completed'];
    }
    if (!isOpen && state === 'denied') {
      return Icons['closed-denied'];
    }
    if (!isOpen && state === 'open') {
      return Icons['closed-open'];
    }
    if (!isOpen && state === 'no_actions') {
      return Icons['closed-no_actions'];
    }
    if (!isOpen && state === 'local_actions') {
      return Icons['closed-local_actions'];
    }
    if (!isOpen && state === 'total_actions') {
      return Icons['closed-total_actions'];
    }
    // console.log(Icons[state]);
    return Icons[state];
  }

  static async waitFor(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static async asyncForEach(source, callback) {
    if (source instanceof Array) {
      for (let index = 0; index < source.length; index++) {
        await callback(source[index], index, source);
      }
    }
    else {
      for (var key in source) {
        if (source.hasOwnProperty(key)) {
          await callback(source[key], key, source);
        }
      }
    }
  }

  static generateId() {
    const id = uuid.v4();
    return id;
  }

  /**
   * Format price
   * 
   * @param sourceValue 
   * @returns 
   */
  static formatPrice(sourceValue: number, decimal: number = 2) {
    // console.log('sourceValue', sourceValue);
    switch (decimal) {
      case 0:
        return formatNumber(sourceValue, 'it-IT', '1.0-0');

      case 2:
        return formatNumber(sourceValue, 'it-IT', '1.2-2');

    }
    // return formatNumber(sourceValue, 'en-US', '1.2-2');
  }

  /**
   * Format percentage
   * 
   * @param sourceValue 
   * @returns 
   */
  static formatPercentage(sourceValue: number) {
    // return formatNumber(sourceValue, 'en-US', '1.2-2');
    return formatNumber(sourceValue, 'it-IT', '1.2-2');
  }

  /**
   * Format time hours
   * 
   * @param sourceValue 
   * @returns 
   */
  static formatTimeHours(sourceValue: number) {
    // return formatNumber(sourceValue, 'en-US', '1.2-2');
    return formatNumber(sourceValue, 'it-IT', '1.2-2');
  }

  /**
   * Format date
   * 
   * @param sourceValue 
   * @returns 
   */
  static formatDate(sourceValue: number) {
    // const formattedString = moment(sourceValue).calendar(null, {
    //   sameDay: '[Today at] HH:mm',
    //   nextDay: '[Tomorrow at] HH:mm',
    //   nextWeek: 'D MMM YYYY [at] HH:mm',
    //   lastDay: '[Yesterday] [at] HH:mm',
    //   lastWeek: 'D MMM YYYY [at] HH:mm',
    //   sameElse: 'D MMM YYYY [at] HH:mm'
    // });

    const formattedString = '-';

    return formattedString;

  }

  static getColor(index: number, format = 'hex') {
    const colors = [
      '#4789F9',
      '#AEDEFF',
      '#003FA0',

      '#83B6E7',
      '#2C67B8',

      '#74A9DF',
      '#3A74C0',

      '#578FD0',
    ];

    return (index < colors.length) ? colors[index] : '#DCE5E8';
  }

}
