import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-button',
  templateUrl: './n7-button.component.html',
  styleUrls: ['./n7-button.component.scss'],
})
export class N7ButtonComponent implements OnChanges {
  public index: number;
  public name: string;
  public selfRef: N7ButtonComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemTitle: string;
  public itemPrefixIcon: string;
  public itemSuffixIcon: string;
  public itemPrefixSrcIcon: string;
  public itemSuffixSrcIcon: string;
  public itemTheme: string;
  public itemIsDisabled: boolean;
  public itemIsFluid: boolean = false;

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set title(val: string) {
    this.itemTitle = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set prefixIcon(val: string) {
    this.itemPrefixIcon = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set suffixIcon(val: string) {
    this.itemSuffixIcon = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set prefixSrcIcon(val: string) {
    this.itemPrefixSrcIcon = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set suffixSrcIcon(val: string) {
    this.itemSuffixSrcIcon = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set theme(val: string) {
    this.itemTheme = (val !== undefined && val !== null) ? val : 'primary';
  }

  @Input() set disabled(val: boolean) {
    this.itemIsDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set fluid(val: boolean) {
    this.itemIsFluid = (val !== undefined && val !== null) ? val : false;
  }

  @Output() itemClick = new EventEmitter()

  constructor() {}

  ngOnChanges() {}

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }

  onItemClick(event) {
    // console.log('itemClick', event);
    this.itemClick.emit();
  }
}
