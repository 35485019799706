import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'n7-hint-popover',
  templateUrl: './n7-hint-popover.component.html',
  styleUrls: ['./n7-hint-popover.component.scss'],
})
export class N7HintPopoverComponent implements OnInit {
  public hintMessage: string;
  public hintPictureUrl: string;
  public dismissLabel: string;

  constructor(
    private popoverController: PopoverController,
    private navParams: NavParams
  ) {
    this.hintMessage = this.navParams.get('hintMessage');
    this.hintPictureUrl = this.navParams.get('hintPictureUrl');;
    this.dismissLabel = this.navParams.get('dismissLabel');;
  }

  ngOnInit() {
  }

  gotIt() {
    this.popoverController.dismiss();
  }
}
