import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'n7-modal-intervent-type',
  templateUrl: './n7-modal-intervent-type.component.html',
  styleUrls: ['./n7-modal-intervent-type.component.scss'],
})
export class N7ModalInterventTypeComponent implements OnInit {
  public task: any = null;
  public interventType: any = null;
  public taskInterventTypes: any[] = [];

  public taskCircleIcons: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.task = this.navParams.get('task');
    this.taskInterventTypes = this.navParams.get('interventTypes');
    this.interventType = _.find(this.taskInterventTypes, {id: this.task.intervent_type_id});

    // console.log('task', this.task);
    // console.log('taskInterventTypes', this.taskInterventTypes);
    // console.log('interventType', this.interventType);
  }

  ngOnInit() {
    // console.log(this.task);
  }

  async dismiss(data) {
    await this.modalController.dismiss({
      'dismissed': true,
      'taskId': this.task.id,
      'interventType': this.interventType,
      'data': data
    });
  }

  changeTaskInterventType() {
    this.dismiss({
      confirmed: true,
      interventType : this.interventType,
      task : this.task,
    });
  }

  cancel() {
    this.dismiss({
      confirmed: false
    });
  }

  onInterventTypeClick(interventType) {
    this.interventType = interventType;
  }
}
