import { Action } from "@ngrx/store";
import { Section } from "./sections.model";

export enum ActionTypes {
  CleanAllSections = "[SECTIONS State] Clean All",

  AddSection = "[SECTIONS State] Add Section",
  RemoveSection = "[SECTIONS State] Remove Section",
}

export class CleanAllSections implements Action {
  readonly type = ActionTypes.CleanAllSections;

  constructor() { }
}

export class AddSection implements Action {
  readonly type = ActionTypes.AddSection;

  constructor(public payload: { data: Section }) { }
}

export class RemoveSection implements Action {
  readonly type = ActionTypes.RemoveSection;

  constructor(public payload: { data: string | number }) { }
}

export type SectionsActions =
  CleanAllSections
  | AddSection
  | RemoveSection;
