import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'app-st-form-checkbox',
  templateUrl: './st-form-checkbox.component.html',
  styleUrls: ['./st-form-checkbox.component.scss'],
})
export class StFormCheckboxComponent implements OnInit {

  public itemValue: string;
  public itemLabel: string;
  public itemChecked: boolean;

  @Input() set value(val: string) {
      this.itemValue = val !== undefined && val !== null ? val : "";
  }
  @Input() set label(val: string) {
      this.itemLabel = val !== undefined && val !== null ? val : "";
  }
  @Input() set checked(val: boolean) {
      this.itemChecked = val !== undefined && val !== null ? val : false;
  }
  @Output() valueChange = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onItemChecked() {
      this.itemChecked = !this.itemChecked;
      this.valueChange.emit({
          value: this.itemValue,
          checked: this.itemChecked,
      });
  }

}
