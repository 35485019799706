import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'n7-modal-gallery',
  templateUrl: './n7-modal-gallery.component.html',
  styleUrls: ['./n7-modal-gallery.component.scss'],
})
export class N7ModalGalleryComponent implements OnInit {
  // @ViewChild('slides') slides: IonSlides;

  public itemType: string;
  public items: any[];
  public index: number;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.itemType = this.navParams.get('itemType');
    this.items = this.navParams.get('items');
    this.index = this.navParams.get('index');
  }

  ngOnInit() {
    // console.log(this.slides);
    // this.slides.lockSwipes(false);
    // this.slides.slideTo(this.index);
  }

  dismiss() {
    this.modalController.dismiss({});
  }
}
