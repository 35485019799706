import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Oauth2Service {
  constructor(
    private http: HttpClient
  ) {
  }

  token(username: string, password: string, scope: string) {
    let url = environment.api.oauth_base_url + 'token';
    let body = {
      grant_type: 'password',
      client_id: environment.api.client_id,
      client_secret: environment.api.client_secret,
      username: username,
      password: password,
      scope: scope
    };
    let options = {};

    return this.http.post(url, body, options);
  }

  logout(access_token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': 'Bearer ' + access_token
    });

    let url = environment.api.auth_base_url + 'logout';
    let options = { headers: headers };

    return this.http.get(url, options);
  }

  tokenRefresh(refresh_token: string) {
    let url = environment.api.oauth_base_url + 'token';
    let body = {
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
      client_id: environment.api.client_id,
      client_secret: environment.api.client_secret
    };
    let options = {};

    return this.http.post(url, body, options);
  }

  user(access_token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': 'Bearer ' + access_token
    });

    let url = environment.api.auth_base_url + 'user';
    let options = { headers: headers };

    return this.http.get(url, options);
  }

  resetPasswordRequest(access_token: string) { }
}
