import * as fromBoat from "./boat.actions";
import { Boat } from "./boat.model";

export interface BoatState {
  boatId: number;
  projectId: number;
  filters: any;
  tasks: any[];
  loading: boolean;
  error: any;
}

export const initialState: BoatState = {
  boatId: 0,
  projectId: 0,
  filters: {},
  tasks: [],
  loading: false,
  error: null
};

export function reducer(
  state = initialState,
  action: fromBoat.ActionsUnion
): BoatState {
  switch (action.type) {
    case fromBoat.ActionTypes.Init: {
      return {
        ...state,
        boatId: action.payload.boatId,
        projectId: action.payload.projectId,
        filters: (action.payload.filters) ? action.payload.filters : {},
        tasks: (action.payload.tasks) ? action.payload.tasks : [],
        loading: true,
        error: null
      };
    }

    case fromBoat.ActionTypes.SetFilters: {
      return {
        ...state,
        filters: action.payload.filters,
        error: null
      };
    }

    case fromBoat.ActionTypes.SetTasks: {
      return {
        ...state,
        tasks: action.payload.tasks,
        error: null
      };
    }

    // case fromUser.ActionTypes.ShowedHint: {
    //   return {
    //     ...state,
    //     showedHints: [...state.showedHints, action.payload.hint]
    //   };
    // }

    default: {
      return state;
    }
  }
}

export const getBoat = (state: BoatState) => state.boatId;

export const getFilters = (state: BoatState) => state.filters;

export const isLoading = (state: BoatState) => state.loading;
