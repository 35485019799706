import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'n7-modal-zone',
  templateUrl: './n7-modal-zone.component.html',
  styleUrls: ['./n7-modal-zone.component.scss'],
})
export class N7ModalZoneComponent implements OnInit {
  public task: any = null;
  public zone: any = null;
  public taskZones: any[] = [];

  public taskCircleIcons: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.task = this.navParams.get('task');
    this.taskZones = this.navParams.get('zones');
    this.zone = this.task.zone;

    // console.log('task', this.task);
    // console.log('taskZones', this.taskZones);
    // console.log('zone', this.zone);
  }

  ngOnInit() {
    // console.log(this.task);
  }

  async dismiss(data) {
    await this.modalController.dismiss({
      'dismissed': true,
      'taskId': this.task.id,
      'zone': this.zone,
      'data': data
    });
  }

  changeTaskZone() {
    this.dismiss({
      confirmed: true,
      zone : ((this.zone) && (this.zone.id)) ? this.zone : null,
      task : this.task,
    });
  }

  cancel() {
    this.dismiss({
      confirmed: false
    });
  }

  onZoneClick(zone) {
    this.zone = zone;
  }
}
