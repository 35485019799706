import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BoatModel } from 'src/app/core/services/boats.service';

@Component({
  selector: 'app-boatcard',
  templateUrl: './boatcard.component.html',
  styleUrls: ['./boatcard.component.scss'],
})

export class BoatcardComponent implements OnChanges {
  public boatCard: BoatModel = null;
  public accesstoken = '';
  private cardRatio: { w: number, h: number };
  public boatStyle = { "padding": "0px 0px 0px 0px" };
  public ratioHeight = '0';

  constructor(
    private router: Router
  ) { }
  @Input() set boat(val: BoatModel) {
    this.boatCard = (val !== undefined && val !== null) ? val : null;
  }
  @Input() set token(val: string) {
    this.accesstoken = (val !== undefined && val !== null) ? val : '';
  }
  @Input() set ratio(ratio: { w: number, h: number }) {
    this.cardRatio = ratio || { w: 2, h: 3 };
  }

  ngOnInit() { }

  ngOnChanges() {
    this.ratioHeight = (this.cardRatio.h / this.cardRatio.w * 100) + '%';
    this.boatStyle.padding = '0px 0px ' + this.ratioHeight + ' 0px';
  }

  openYacht() {
    // console.log('this.boatCard', this.boatCard);
    const projectId = (this.boatCard.project && this.boatCard.project.id) ? this.boatCard.project.id : 0;
    this.router.navigateByUrl('boat/' + this.boatCard.id + '/project/' + projectId);
  }
}
