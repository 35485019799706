import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [LoginGuard]
  },
  { path: 'reset-password', loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard] },

  { path: 'shipyard/:shipyard', loadChildren: () => import('./pages/shipyard/shipyard.module').then(m => m.ShipyardPageModule), canActivate: [AuthGuard] },
  { path: 'shipyard/:shipyard/edit', loadChildren: () => import('./pages/shipyard-edit/shipyard-edit.module').then(m => m.ShipyardEditPageModule), canActivate: [AuthGuard] },

  { path: 'add-new-yacht', loadChildren: () => import('./pages/add-new-yacht/add-new-yacht.module').then(m => m.AddNewYachtPageModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'add-new-project/:boat_id/:project_type', loadChildren: () => import('./pages/add-new-project/add-new-project.module').then(m => m.AddNewProjectPageModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'add-yacht-history/:boat_id', loadChildren: () => import('./pages/add-yacht-history/add-yacht-history.module').then(m => m.AddYachtHistoryPageModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'project-sections/:project_id/:boat_id', loadChildren: () => import('./pages/project-sections/project-sections.module').then(m => m.ProjectSectionsPageModule) },
  { path: 'boat/:boat_id/project/:project_id/edit/sections', loadChildren: () => import('./pages/project-sections/project-sections.module').then(m => m.ProjectSectionsPageModule) },
  { path: 'project-users/:project_id/:boat_id', loadChildren: () => import('./pages/project-users/project-users.module').then(m => m.ProjectUsersPageModule) },
  { path: 'project-zones/:project_id/:boat_id', loadChildren: () => import('./pages/project-zones/project-zones.module').then(m => m.ProjectZonesPageModule) },
  { path: 'project-products/:project_id/:boat_id', loadChildren: () => import('./pages/project-products/project-products.module').then(m => m.ProjectProductsPageModule) },

  { path: 'boat/:boat_id/project/:project_id/edit/drive-folder', loadChildren: () => import('./pages/project-drive-folder/project-drive-folder.module').then(m => m.ProjectDriveFolderPageModule), canActivate: [AuthGuard] },
  { path: 'boat/:boat_id/project/:project_id/edit/budget', loadChildren: () => import('./pages/project-budget/project-budget.module').then(m => m.ProjectBudgetPageModule), canActivate: [AuthGuard] },

  { path: 'edit-yacht/:boat_id', loadChildren: () => import('./pages/edit-yacht/edit-yacht.module').then(m => m.EditYachtPageModule), canActivate: [AuthGuard] },
  // { path: 'edit-project/:project_id', loadChildren: () => import('./pages/edit-project/edit-project.module').then(m => m.EditProjectPageModule) },

  // { path: 'boat/:boat_id', loadChildren: () => import('./pages/boat/boat.module').then(m => m.BoatPageModule) },
  { path: 'boat/:boat_id/project/:project_id', loadChildren: () => import('./pages/boat/boat.module').then(m => m.BoatPageModule), canActivate: [AuthGuard] },

  // parte di aggiunta del punto
  // tslint:disable-next-line: max-line-length
  { path: 'boat/:boat_id/project/:project_id/task/:task_id', loadChildren: () => import('./pages/task/task.module').then(m => m.TaskPageModule), canActivate: [AuthGuard] },

  // tslint:disable-next-line: max-line-length
  { path: 'add-draft-map/:section_id/:project_id/:boat_id', loadChildren: () => import('./pages/add-draft-map/add-draft-map.module').then(m => m.AddDraftMapPageModule) }, // 1
  { path: 'add-draft-photo/:task_id', loadChildren: () => import('./pages/add-draft-photo/add-draft-photo.module').then(m => m.AddDraftPhotoPageModule) },// 2
  { path: 'add-draft-draw/:task_id', loadChildren: () => import('./pages/add-draft-draw/add-draft-draw.module').then(m => m.AddDraftDrawPageModule) },// 3
  { path: 'add-draft-info/:task_id', loadChildren: () => import('./pages/add-draft-info/add-draft-info.module').then(m => m.AddDraftInfoPageModule) },// 4
  // all section page
  {
    path: 'boat/:boat_id/project/:project_id/sections',
    loadChildren: () => import('./pages/all-sections/all-sections.module').then(m => m.AllSectionsPageModule)
  },

  // Exports
  { path: 'boat/:boat_id/project/:project_id/export/list-of-points', loadChildren: () => import('./pages/export-list-of-points/export-list-of-points.module').then(m => m.ExportListOfPointsPageModule) },
  { path: 'boat/:boat_id/project/:project_id/export/environmentals', loadChildren: () => import('./pages/export-environmentals/export-environmentals.module').then(m => m.ExportEnvironmentalsPageModule) },
  { path: 'boat/:boat_id/project/:project_id/export/application', loadChildren: () => import('./pages/export-application/export-application.module').then(m => m.ExportApplicationPageModule) },
  { path: 'boat/:boat_id/project/:project_id/export/available-surface', loadChildren: () => import('./pages/export-available-surface/export-available-surface.module').then(m => m.ExportAvailableSurfacePageModule) },

  // Logs
  { path: 'boat/:boat_id/project/:project_id/log/application', loadChildren: () => import('./pages/application-log/application-log.module').then(m => m.ApplicationLogPageModule) },
  { path: 'boat/:boat_id/project/:project_id/log/environmental', loadChildren: () => import('./pages/environmental-log/environmental-log.module').then(m => m.EnvironmentalLogPageModule) },
  { path: 'boat/:boat_id/project/:project_id/surface/availability', loadChildren: () => import('./pages/surface-availability/surface-availability.module').then(m => m.SurfaceAvailabilityPageModule) },

  // Remark flow
  {
    path: 'boat/:boat_id/project/:project_id/log/remark/:document_id',
    loadChildren: () => import('./pages/remark-log/remark-log.module').then(m => m.RemarkLogPageModule)
  },

  { path: 'statics/theme', loadChildren: () => import('./statics/theme/theme.module').then(m => m.ThemePageModule) },
  { path: 'statics/icons', loadChildren: () => import('./statics/icons/icons.module').then(m => m.IconsPageModule) },
  { path: 'statics/home', loadChildren: () => import('./statics/home/home.module').then(m => m.HomeStaticPageModule) },
  { path: 'statics/shipyard', loadChildren: () => import('./statics/shipyard/shipyard.module').then(m => m.ShipyardStaticPageModule) },
  { path: 'statics/resources', loadChildren: () => import('./statics/resources/resources.module').then(m => m.ResourcesStaticPageModule) },
  { path: 'statics/management', loadChildren: () => import('./statics/management/management.module').then(m => m.ManagementPageModule) },
  { path: 'statics/surface-report', loadChildren: () => import('./statics/surface-report/surface-report.module').then(m => m.SurfaceReportPageModule) },
  { path: 'statics/surface-available', loadChildren: () => import('./statics/surface-available/surface-available.module').then(m => m.SurfaceAvailablePageModule) },
  { path: 'statics/project-set-budget', loadChildren: () => import('./statics/project-set-budget/project-set-budget.module').then(m => m.ProjectSetBudgetPageModule) },
  { path: 'drafts', loadChildren: () => import('./pages/boat/drafts/drafts.module').then(m => m.DraftsPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
