// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  padding: 16px;
  color: #FFFFFF;
  text-align: right;
  background-color: #000000;
}
.modal-header > ion-icon {
  font-size: 32px;
}

.modal-content {
  flex-grow: 1;
  background-color: #000000;
}

.swiper-container {
  height: 100%;
  max-height: calc(100vh - 68px);
}

.hint-wrapper {
  padding: 16px;
  text-align: center;
}

.hint-picture {
  max-width: 100%;
}

.hint-message {
  margin: 0 auto 16px;
  padding: 16px;
  border-bottom: 2px solid #DBDBDB;
  color: #585858;
  font-size: 14px;
  letter-spacing: -0.1px;
  text-align: center;
}

.hint-link {
  margin: 16px auto 0;
  color: var(--ion-color-primary);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.1px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/n7-modal-gallery/n7-modal-gallery.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EACA,iBAAA;EACA,yBAAA;AACJ;AACI;EACI,eAAA;AACR;;AAGA;EACI,YAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,aAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,aAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,+BAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;AAAJ","sourcesContent":[".modal-header {\n    padding: 16px;\n    color: #FFFFFF;\n    text-align: right;\n    background-color: #000000;\n\n    > ion-icon {\n        font-size: 32px;\n    }\n}\n\n.modal-content {\n    flex-grow: 1;\n    background-color: #000000;\n}\n\n.swiper-container {\n    height: 100%;\n    max-height: calc(100vh - 68px);\n}\n\n.hint-wrapper {\n    padding: 16px;\n    text-align: center;\n}\n\n.hint-picture {\n    max-width: 100%;\n}\n\n.hint-message {\n    margin: 0 auto 16px;\n    padding: 16px;\n    border-bottom: 2px solid #DBDBDB;\n    color: #585858;\n    font-size: 14px;\n    letter-spacing: -0.1px;\n    text-align: center;\n}\n\n.hint-link {\n    margin: 16px auto 0;\n    color: var(--ion-color-primary);\n    font-size: 14px;\n    font-weight: bold;\n    letter-spacing: -0.1px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
