// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-title {
  margin: 0;
  padding: 16px;
  border-bottom: 1px solid #DDDDDD;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 1.2;
  text-align: center;
}

.suggestion {
  box-sizing: border-box;
  margin: 0;
  padding: 16px;
  border-bottom: 1px solid #DDDDDD;
  background-color: #FFFFFF;
}
.suggestion:last-child {
  border-bottom: 0;
}
.suggestion .suggestions-label {
  color: #4A4A4A;
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 1.2;
  cursor: pointer;
}
.suggestion.selected {
  background-color: #BEE7FF;
}
.suggestion.selected .suggestions-label {
  color: var(--ion-color-primary);
  font-weight: 600;
  cursor: initial;
}`, "",{"version":3,"sources":["webpack://./src/app/components/n7-modal-suggestions/n7-modal-suggestions.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,aAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,SAAA;EACA,aAAA;EACA,gCAAA;EACA,yBAAA;AACJ;AACI;EACI,gBAAA;AACR;AAEI;EACI,cAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;AAAR;AAGI;EACI,yBAAA;AADR;AAGQ;EACI,+BAAA;EACA,gBAAA;EACA,eAAA;AADZ","sourcesContent":[".modal-title {\n    margin: 0;\n    padding: 16px;\n    border-bottom: 1px solid #DDDDDD;\n    color: #000000;\n    font-size: 16px;\n    font-weight: 600;\n    letter-spacing: -0.17px;\n    line-height: 1.2;\n    text-align: center;\n}\n\n.suggestion {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 16px;\n    border-bottom: 1px solid #DDDDDD;\n    background-color: #FFFFFF;\n\n    &:last-child {\n        border-bottom: 0;\n    }\n\n    .suggestions-label {\n        color: #4A4A4A;\n        font-size: 16px;\n        letter-spacing: -0.17px;\n        line-height: 1.2;\n        cursor: pointer;\n    }\n\n    &.selected {\n        background-color: #BEE7FF;\n\n        .suggestions-label {\n            color: var(--ion-color-primary);\n            font-weight: 600;\n            cursor: initial;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
