import { Injectable } from '@angular/core'; 
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
 

@JsonApiModelConfig({
  type: 'boats'
})
export class BoatModel extends JsonApiModel {
  @Attribute()
  name: string;

  @Attribute()
  registration_number: string;

  @Attribute()
  flag: string;

  @Attribute()
  manufacture_year: number;

  @Attribute()
  length: number;

  @Attribute()
  draft: number;

  @Attribute()
  beam: number;

  @Attribute()
  boat_type: string;

  @Attribute()
  site_id: number;

  @Attribute()
  project: any;

  @Attribute()
  owner: any;

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}
 
const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    boats: BoatModel
  }
}

@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class BoatsService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public getSections<T extends JsonApiModel>(
    modelType: ModelType<T>,
    id: string,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string
  ): Observable<any> {
    const url: string = this.buildUrl(modelType, params, id, null, customUrl) + '/relationships/sections';
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => { 
          return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }
}