// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hint-wrapper {
  padding: 16px;
  text-align: center;
}

.hint-picture {
  max-width: 100%;
}

.hint-message {
  margin: 0 auto 16px;
  padding: 16px;
  border-bottom: 2px solid #DBDBDB;
  color: #585858;
  font-size: 14px;
  letter-spacing: -0.1px;
  text-align: center;
}

.hint-link {
  margin: 16px auto 0;
  color: var(--ion-color-primary);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.1px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/n7-draw-on-photo/n7-draw-on-photo.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,+BAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;AACJ","sourcesContent":[".hint-wrapper {\n    padding: 16px;\n    text-align: center;\n}\n\n.hint-picture {\n    max-width: 100%;\n}\n\n.hint-message {\n    margin: 0 auto 16px;\n    padding: 16px;\n    border-bottom: 2px solid #DBDBDB;\n    color: #585858;\n    font-size: 14px;\n    letter-spacing: -0.1px;\n    text-align: center;\n}\n\n.hint-link {\n    margin: 16px auto 0;\n    color: var(--ion-color-primary);\n    font-size: 14px;\n    font-weight: bold;\n    letter-spacing: -0.1px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
