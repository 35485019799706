import { UserService } from 'src/app/core/services/user.service';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StateModule } from './state/state.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { MessageService } from './core/services/MessageService.service';
import { NetworkService } from './core/services/network.service';
import { RecoveryService } from './core/services/recovery.service';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeIt);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      // Prevent caching in IE, in particular IE11.
      // See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      hardwareBackButton: false
    }),
    AppRoutingModule,
    IonicStorageModule.forRoot(
      {
        name: '__snaglist_web_db',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }
    ),
    ComponentsModule,
    StateModule.forRoot(),
    // CoreModule.forRoot(),
    HttpClientModule,
    // BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [
    TranslateModule,
    ComponentsModule
  ],
  providers: [
    // StatusBar,
    // SplashScreen,
    // CameraPreview,
    // Camera,
    // Base64,
    // WebView,
    // PhotoViewer,
    // BackgroundMode,
    MessageService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserService,
    // File,
    // Network,
    NetworkService,
    RecoveryService,
    ScreenOrientation,
    // InAppBrowser,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'it-IT' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
