// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-md:after {
  opacity: 0;
}

ion-toolbar {
  border-bottom: 1px solid #EBEBEB;
}

.link {
  cursor: pointer;
}

.breadcrumbs {
  display: block;
  color: #666;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}
.breadcrumbs.current {
  font-weight: bold;
}
.breadcrumbs img {
  width: 28px;
  height: auto;
  vertical-align: middle;
}

ion-button {
  position: relative;
  overflow: visible !important;
  color: #cccccc;
}
ion-button.hidden {
  opacity: 0;
}
ion-button ion-label {
  text-transform: capitalize;
  letter-spacing: 0;
}
ion-button ion-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  border-radius: 50%;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.09px;
  line-height: 1.5em;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #5396E4;
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-header/app-header.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,sBAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,4BAAA;EACA,cAAA;AADF;AAGE;EACE,UAAA;AADJ;AAQE;EACE,0BAAA;EACA,iBAAA;AANJ;AASE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;EACA,yBAAA;AAPJ","sourcesContent":[".header-md:after {\n  opacity: 0;\n}\n\nion-toolbar {\n  border-bottom: 1px solid #EBEBEB;\n}\n\n.link {\n  cursor: pointer;\n}\n\n.breadcrumbs {\n  display: block;\n  color : #666;\n  font-size: 16px;\n  font-weight: 500;\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  &.current {\n    font-weight: bold;\n  }\n\n  img {\n    width: 28px;\n    height: auto;\n    vertical-align: middle;\n  }\n}\n\nion-button {\n  position: relative;\n  overflow: visible!important;\n  color: #cccccc;\n\n  &.hidden {\n    opacity: 0;\n  }\n\n  // ion-icon[slot=start] {\n  //   margin-right: -4px;\n  // }\n\n  ion-label {\n    text-transform: capitalize;\n    letter-spacing: 0;\n  }\n\n  ion-badge {\n    position: absolute;\n    top: 5px;\n    right: 5px;\n    width: 1.5em;\n    height: 1.5em;\n    padding: 0;\n    border-radius: 50%;\n    color: #FFFFFF;\n    font-size: 12px;\n    font-weight: bold;\n    letter-spacing: -0.09px;\n    line-height: 1.5em;\n    text-align: center;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    background-color: #5396E4;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
