import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface componentInteraction {
  remove(index: number);
}

@Component({
  selector: 'n7-data-input',
  templateUrl: './n7-data-input.component.html',
  styleUrls: ['./n7-data-input.component.scss'],
})
export class N7DataInputComponent implements OnChanges {
  public index: number;
  public name: string;
  public selfRef: N7DataInputComponent;
  //interface for Parent-Child interaction
  public compInteraction: componentInteraction;

  public itemLabel: string;
  public itemHint: string;
  public itemHasError: boolean;
  public itemError: string;
  public itemValue: string;
  public itemValues: any[];
  public itemUnit: string;
  public itemType: string;
  public itemPlaceholder: string;
  public itemPrefix: string;
  public itemHasAction: boolean;
  public itemRegexp: string;
  public itemDisabled: boolean;
  public itemSelectedInput: string;

  public checkedOption: string;

  @Input() set label(val: string) {
    this.itemLabel = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hint(val: string) {
    this.itemHint = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasError(val: boolean) {
    this.itemHasError = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set error(val: string) {
    this.itemError = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set value(val: string) {
    this.itemValue = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set values(val: any[]) {
    this.itemValues = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set unit(val: string) {
    this.itemUnit = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set type(val: string) {
    this.itemType = (val !== undefined && val !== null) ? val : 'text';
  }

  @Input() set placeholder(val: string) {
    this.itemPlaceholder = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set prefix(val: string) {
    this.itemPrefix = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hasAction(val: boolean) {
    this.itemHasAction = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set regexp(val: string) {
    this.itemRegexp = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set disabled(val: boolean) {
    this.itemDisabled = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set selectedInput(val: string) {
    this.itemSelectedInput = (val !== undefined && val !== null) ? val : '';
  }

  @Output() valueChange = new EventEmitter()

  constructor() {}

  ngOnChanges() {
    this.checkErrors()
  }

  removeMe(index) {
    if (this.compInteraction) {
      this.compInteraction.remove(index)
    }
  }
  onItemClear(event) {
    this.itemValues = [];
    this.itemHasError = false;
    // this.itemError = '';
    this.valueChange.emit(this.itemValues);
  }

  onItemChange(event) {
    this.checkErrors()
    this.valueChange.emit(this.itemValues);
  }

  onKeyUp(event) {
    this.onItemChange(event);
  }

  checkErrors() {
    if (this.itemValue && this.itemRegexp) {
      const regexp = new RegExp(this.itemRegexp);
      const isValid = regexp.test(this.itemValue);
      if (isValid) {
        this.itemHasError = false;
      }
      else {
        this.itemHasError = true;
      }
    }
    else {
      this.itemHasError = false;
    }
  }

  itemUploadData(event) {
    console.log('Upload event', event)
    // this.itemSelectedInput = 'file';
  }

  itemInsertManually(event) {
    this.itemSelectedInput = 'manual';
  }

  onItemManuallyChange(index, event) {
    this.itemValues[index].value = (event && (event.length > 0)) ? event : null;
    this.onItemChange(event);
  }
}
