import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute, ModelType } from './jsonapi.service';
import { environment } from '../../../environments/environment';

@JsonApiModelConfig({
  type: 'sections'
})
export class SectionModel extends JsonApiModel {
  @Attribute()
  name: string;

  @Attribute()
  section_type: string;

  @Attribute()
  position: string;

  @Attribute()
  code: string;

  @Attribute()
  boat_id: number;

  @Attribute()
  image: string;

  @Attribute()
  tasks_count: number;

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}
const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    boats: SectionModel
  }
}
@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class SectionService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public getSection(modelType, id: string) {
    return this.getEntity(modelType, id);
  }
}