import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

export interface Option {
  key: string,
  label: string,
}

@Component({
  selector: 'app-st-modal-register-invoice',
  templateUrl: './st-modal-register-invoice.component.html',
  styleUrls: ['./st-modal-register-invoice.component.scss'],
})
export class StModalRegisterInvoiceComponent implements OnInit {
  companyId: string;
  zoneId: string;
  amount: number;
  companyOptions: Option[];
  companyOption: Option;
  zoneOptions: Option[];
  zoneOption: Option;
  validInvoice: boolean;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
  ) {
    this.companyOptions = this.navParams.get('companyOptions');
    this.zoneOptions = this.navParams.get('companyOptions');

    this.init();
  }

  ngOnInit() { }

  init() {
    this.validInvoice = false;
    this.companyId = null;
    this.zoneId = null;
  }

  validate() {
    this.validInvoice = false;
    if (!this.companyId) {
      return;
    }
    // console.log('this.zoneId', this.zoneId);
    if (!this.zoneId) {
      return;
    }
    if (!this.amount) {
      return;
    }
    this.validInvoice = true;
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async submit() {
    this.validInvoice = false;
    this.modalController.dismiss({
      'dismissed': true,
      'invoice': {
        companyId: this.companyId,
        amount: this.amount,
        zoneId: this.zoneId
      }
    });
  }

  onCompanySelected($event) {
    // console.log($event);
    if ('string' === typeof $event) {
      const companyOptionIndex = this.companyOptions.findIndex((o) => ((o.key) === ($event)));
      this.companyOption = (this.companyId && (companyOptionIndex >= 0)) ? this.companyOptions[companyOptionIndex] : null;
      this.companyId = $event;
      this.validate();
    }
    else {
      this.companyOption = $event;
      this.companyId = this.companyOption.key;
      this.validate();
    }
  }

  onZoneSelected($event) {
    // console.log($event);
    if ('string' === typeof $event) {
      const zoneOptionIndex = this.zoneOptions.findIndex((o) => ((o.key) === ($event)));
      this.zoneOption = (this.zoneId && (zoneOptionIndex >= 0)) ? this.zoneOptions[zoneOptionIndex] : null;
      this.zoneId = $event;
      this.validate();
    }
    else {
      this.zoneOption = $event;
      this.zoneId = this.zoneOption.key;
      this.validate();
    }
  }


}
