import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'app-st-card-shipyard',
  templateUrl: './st-card-shipyard.component.html',
  styleUrls: ['./st-card-shipyard.component.scss'],
})
export class StCardShipyardComponent implements OnInit {
  @Input() set shipyardCard(val: any) {
    this.itemShipyardCard = val !== undefined && val !== null ? val : [];
  }

  @Input() set ratio(ratio: { w: number; h: number }) {
    this.cardRatio = ratio || { w: 2, h: 3 };
  }

  @Input() set token(val: string) {
    this.accessToken = (val !== undefined && val !== null) ? val : '';
  }

  @Output() itemClick = new EventEmitter()

  public itemShipyardCard: any;
  public accessToken = "";
  public shipyardStyle = { padding: "0px 0px 0px 0px" };
  public ratioHeight = "0";
  private cardRatio: { w: number; h: number };

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this.ratioHeight = (this.cardRatio.h / this.cardRatio.w) * 100 + "%";
    this.shipyardStyle.padding = "0px 0px " + this.ratioHeight + " 0px";
  }

  openShipyard() {
    this.itemClick.emit();
  }
}
