import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'n7-draw-on-photo',
  templateUrl: './n7-draw-on-photo.component.html',
  styleUrls: ['./n7-draw-on-photo.component.scss'],
})
export class N7DrawOnPhotoComponent implements OnInit {

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  gotIt() {
    this.popoverController.dismiss();
  }
}
