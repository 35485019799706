import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { endOfMonth, startOfMonth, subDays, subMonths } from 'date-fns';
// import * as moment from 'moment';

@Component({
  selector: 'app-st-daterange-picker',
  templateUrl: './st-daterange-picker.component.html',
  styleUrls: ['./st-daterange-picker.component.scss'],
})
export class StDaterangePickerComponent implements OnInit {
  itemThemeSmall: boolean;
  selected: any;
  itemInitialStart: string;
  itemInitialEnd: string;

  @Input() set themeSmall(val: boolean) {
    this.itemThemeSmall = val !== undefined && val !== null ? val : false;
  }

  @Input() set initialStart(val: string) {
    this.itemInitialStart = val !== undefined && val !== null ? val : null;
  }

  @Input() set initialEnd(val: string) {
    this.itemInitialEnd = val !== undefined && val !== null ? val : null;
  }

  @Output() rangeChange = new EventEmitter()

  ranges: any = {
    'Last 30 Days': [subDays(new Date(), 29), new Date()],
    'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
    'Last Month': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
  }

  constructor() {
    this.selected = {
      start: subDays(new Date(), 29),
      end: new Date()
    };
  }

  ngOnInit() { }

  ngOnChanges() {
    this.selected = {
      start: (this.itemInitialStart) ? new Date(this.itemInitialStart) : subDays(new Date(), 29),
      end: (this.itemInitialEnd) ? new Date(this.itemInitialEnd) : new Date()
    };
  }

  changeRange(e) {
    this.rangeChange.emit(e);
  }
}
